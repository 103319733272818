















































import { LogLevels } from "@/models/log/interface";

export default {
  name: "VerifyEmail",
  components: {},
  data() {
    return {
      resendLoading: false,
      resendDisabled: false
    };
  },
  methods: {
    resendVerificationEmail: function() {
      this.resendLoading = true;
      this.$store
        .dispatch("user/sendVerificationEmail")
        .then(() => {
          this.$store.commit("showMessage", {
            text: this.$t("views/verify_email/email_sent"),
            color: "success"
          });
        })
        .catch(error => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
        })
        .finally(() => {
          this.resendLoading = false;
          this.resendDisabled = true;
        });
    }
  }
};
