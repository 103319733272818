<template>
  <div v-if="!loading" class="centered-content">
    <div class="text-h5 text-sm-h3 page-title">
      {{ $t("views/testCategories/title") }}
    </div>

    <v-switch
      v-if="userIsAdmin"
      v-model="adminMode"
      dark
      label="Modo administrador"
    />
    <v-btn
    v-if="adminMode"
      color="red"
      class="mr-4"
      dark
      @click="addTest()"
      style="margin-bottom:10px;"
    >
      Agregar Test
    </v-btn>

    <router-link
      v-for="cat in availableCategories"
      :key="cat.code"
      :to="{
        name: 'tests',
        params: { category: cat.code, title: cat.name },
      }"
      style="text-decoration: none; color:white; font-size: 18px;"
    >
      <div class="test-link">
        <v-icon left dark>
          mdi-book-education-outline
        </v-icon>
        {{ cat.name }}
      </div>
    </router-link>
  </div>
</template>

<script>
import Vuex from "vuex";
import Vue from "vue";
import { LogLevels } from "@/models/log/interface";
import * as firebase from "firebase/app";
export default Vue.extend({
  name: "TestCategories",
  components: {},
  data() {
    return {
      loading: true,
      availableCategories: [],
      userIsAdmin: false,
      adminMode: false,
    };
  },
  created() {
    this.getAdmin();
    this.$store
      .dispatch("tests/getTestCategories")
      .then((categories) => {
        this.availableCategories = categories;
      })
      .catch((error) => {
        //console.log(error);
        this.$store.dispatch("log", {
          level: LogLevels.ERROR,
          message: error,
        });
        this.$store.commit("showMessage", {
          text: this.$t(
            "views/test_categories/error_messages/error_fetching_categories"
          ),
          color: "error",
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    addTest() {
      this.$router.push( {name: "test_input"});
    },
    getAdmin() {
      firebase
        .firestore()
        .collection("resources")
        .doc("admin-users")
        .get()
        .then((doc) => {
          const found = doc.data().list.includes(this.$store.state.user.id);
          this.userIsAdmin = found;
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
});
</script>

<style scoped>
.centered-content {
  color: white;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 50px;
  max-width: 1000px;
  align-items: center;
}

.page-title {
  margin-bottom: 40px;
}

.test-link {
  background: #0a0d1a;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 10px;
}

.test-link:hover {
  background: #11162c;
  margin-bottom: 10px;
  padding: 16px;
}

.hero-image {
  background-image: url(../assets/banner-2-blue.jpg);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
