const ERROR = "error_web";
const WARNING = "warning_web";
const INFO = "info_web";

export const LogLevels = {
  ERROR: ERROR,
  WARNING: WARNING,
  INFO: INFO
};
export type LogLevel = typeof ERROR | typeof WARNING | typeof INFO;
