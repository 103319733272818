<template>
  <div class="d-flex justify-center mt-4">
    <div class="container-precio" :style="isDialog ? 'margin: 0;' : ''">
      <div class="container-info-precio">
        <!-- <v-switch
          v-if="isDialog"
          v-model="switchIsMensual"
          flat
          :label="switchIsMensual ? 'ver anual' : 'ver mensual'"
        /> -->
        <div class="precio">
          <h2 v-if="isAnual">
            <span class="precio-cifra">700€</span><small>/año</small>
          </h2>
          <h2 v-else-if="isSemestral">
            <span class="precio-cifra">360€</span><small>/semestre</small>
          </h2>
          <h2 v-if="isMensual">
            <span class="precio-cifra">80€</span><small>/mes</small>
          </h2>
        </div>
        <div v-if="isAnual">
          <v-chip color="red" label dark>
            <strong>AHORRA 260€</strong>
          </v-chip>
        </div>
        <div v-else-if="isSemestral">
          <v-chip color="red" label dark>
            <strong>AHORRA 120€</strong>
          </v-chip>
        </div>
        <div class="info-acceso">
          <p v-if="isAnual" style="margin-bottom:0px;">
            facturados <strong>anualmente</strong>
          </p>
          <p v-else-if="isSemestral" style="margin-bottom:0px;">
            facturados <strong>semestralmente</strong>
          </p>
          <p v-else-if="isMensual" style="margin-bottom:0px;">
            facturados <strong>mensualmente</strong>
          </p>
        </div>
        <div
          v-for="(item, index) in servicios"
          :key="index + item.servicio"
          class="container-info-detalles"
        >
          <div class="container-clases d-flex flex-row">
            <i class="fas fa-check-circle mr-2" />
            {{ item.servicio }}
          </div>
        </div>

        <v-btn
          color="#202741"
          dark
          large
          class="button-info"
          @click="empiezaHoyButtonAction"
        >
          Empieza hoy
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrecioLanding",
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
    isMensual: {
      type: Boolean,
      default: false,
    },
    isAnual: {
      type: Boolean,
      default: false,
    },
    isSemestral: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      switchIsMensual: !this.isAnual,
      servicios: [
        {
          servicio: "+600h de contenido",
        },
        {
          servicio: "Clases semanales",
        },
        {
          servicio: "Plataforma de tests",
        },
        {
          servicio: "Tutorías individuales",
        },
        {
          servicio: "Tests psicotécnicos",
        },
        {
          servicio: "Preparación física",
        },
        {
          servicio: "Técnicas de estudio",
        },
      ],
    };
  },
  methods: {
    empiezaHoyButtonAction() {
      // No ha iniciado sesion
      if (this.$store.state.user.token === null) {
        this.$router.push({ name: "register" });
      } else if (
        Object.keys(this.$store.state.user.subscribedSince).length === 0
      ) {
        this.$router.push({ name: "profile" });
      } else {
        alert("Ya estás suscrito :)!");
      }
    },
  },
};
</script>

<style scoped>
.container-precio {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.19) 5px 5px 10px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.container-info-precio {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 1rem;
  color: #202741;
}

.container-info-detalles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container-clases {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem;
  width: 70%;
}
.container-clases p {
  margin: 0;
}

.precio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.info-acceso {
  margin: 1rem;
  font-weight: 300;
  font-size: 17px;
  padding-left: 2.1rem;
  padding-right: 2.1rem;
}

.precio-cifra {
  font-family: "Oswald";
  font-size: 3rem;
}

.button-info {
  margin: 1rem;
  width: 70%;
}

@media screen and (max-width: 400px) {
  .precio-cifra {
    font-size: 2.5rem;
  }

  .container-clases p {
    font-size: 0.9rem;
  }

  .container-clases {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem;
    width: 75%;
  }

  .info-acceso {
    font-size: 16px;
    text-align: center;
  }
}
</style>
