






















































































































import Vue from "vue";
import { LogLevels } from "@/models/log/interface";

export default Vue.extend({
  data() {
    return {
      //dialog: false,
      pageTitle: 'Simulacro de examen',
      testMetadata: null,
      simulationTestId: null, //"VTxyMyt3QjdqV3InfaBu",
      dates: {
        examStartTime: null,
        examEndTime: null,
        resultsAvailableTime: null
      },
      button: {
        loading: true,
        text: "",
        enabled: false,
        onclick: () => {
          /**/
        },
        description: ""
      }
    };
  },
  mounted() {
    this.$store
      .dispatch("tests/getActiveSimulationTestId")
      .then(activeSimulationTestId => {
        this.simulationTestId = activeSimulationTestId;
        this.$store
          .dispatch("tests/getExam", activeSimulationTestId)
          .then(test => {
            this.testMetadata = test;
            this.pageTitle = test.title;
            this.dates.examStartTime = test.startDate.toDate();
            this.dates.examEndTime = test.endDate.toDate();
            this.dates.resultsAvailableTime = test.resultsDate.toDate();
            this.initializeClock("clockdiv", this.dates.examStartTime);
            this.initializeButton();
          })
          .catch(error => {
            this.$store.dispatch("log", {
              level: LogLevels.ERROR,
              message: error
            });
            this.$store.commit("showMessage", {
              text: this.$t(
                "views/landing_simulacro/error_messages/error_loading"
              ),
              color: "error"
            });
          });
      })
      .catch(error => {
        this.button.description = "El próximo simulacro se anunciará pronto";
        this.$store.dispatch("log", { level: LogLevels.ERROR, message: error });
      });
  },
  methods: {
    getTimeRemaining: function(endtime) {
      const total = endtime - Date.parse(new Date().toString());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return {
        total,
        days,
        hours,
        minutes,
        seconds
      };
    },
    initializeClock: function(id, endtime) {
      const clock = document.getElementById(id);
      const timeinterval = setInterval(() => {
        const t = this.getTimeRemaining(endtime);
        clock.innerHTML =
          "⏳ Faltan " +
          t.days +
          " días, " +
          t.hours +
          " horas, " +
          +t.minutes +
          " minutos y " +
          t.seconds +
          " segundos ⏳";
        if (t.total <= 0) {
          clock.innerHTML =
            "⏳ Faltan " +
            0 +
            " días, " +
            0 +
            " horas, " +
            +0 +
            " minutos y " +
            0 +
            " segundos ⏳";
          clearInterval(timeinterval);
        }
      }, 1000);
    },
    initializeButton: async function(): Promise<void> {
      this.button.loading = true;
      this.button.text = "";
      this.button.enabled = false;
      this.button.onclick = () => {
        /**/
      };
      this.button.description = "";

      let currentDate = new Date();
      await this.$store
        .dispatch("getRemoteDate")
        .then(result => {
          currentDate = result;
        })
        .catch(() => {
          currentDate = new Date();
        });

      const isExamPeriod =
        new Date(this.dates.examStartTime) <= currentDate &&
        currentDate <= this.dates.examEndTime;

      const userId = this.$store.state.user.id;
      this.$store
        .dispatch("tests/getUserSubmission", {
          testId: this.simulationTestId,
          userId: userId
        })
        .then(result => {
          if (result != null) {
            // There is a user submission started
            if (
              result.ended &&
              currentDate >= this.dates.resultsAvailableTime
            ) {
              this.button.enabled = true;
              this.button.text = "Ver mis resultados";
              this.button.onclick = () => {
                this.$router.push({
                  name: "result",
                  params: {
                    testId: this.simulationTestId,
                    submissionId: result.submissionId
                  }
                });
              };
              this.button.description =
                "🎉 ¡Los resultados ya están listos! 🎉";
            } else if (!result.ended && isExamPeriod) {
              this.button.enabled = true;
              this.button.text = "Ir al examen";
              this.button.onclick = () => {
                this.$router.push({
                  name: "test",
                  params: { testId: this.simulationTestId }
                });
              };
              this.button.description = "Continúa con el examen";
            } else if (
              result.ended &&
              currentDate < this.dates.resultsAvailableTime
            ) {
              this.button.enabled = false;
              this.button.text = "Ver mis resultados";
              this.button.onclick = () => {
                /**/
              };
              this.button.description =
                "Los resultados estarán disponibles el " +
                this.dates.resultsAvailableTime.toLocaleDateString() +
                " a las " +
                this.dates.resultsAvailableTime.toLocaleTimeString();
            } else {
              this.button.enabled = false;
              this.button.text = "Ver mis resultados";
              this.button.onclick = () => {
                /**/
              };
              this.button.description =
                "El examen no fue enviado. Tus resultados no están disponibles";
            }
          } else {
            // There is not a user submission
            if (currentDate < new Date(this.dates.examStartTime)) {
              this.button.enabled = false;
              this.button.text = "Ir al examen";
              this.button.onclick = () => {
                //this.dialog = true;
              };
              this.button.description = "El examen todavía no está disponible";
            } else if (isExamPeriod) {
              this.button.enabled = true;
              this.button.text = "Ir al examen";
              this.button.onclick = () => {
                this.$router.push({
                  name: "test",
                  params: { testId: this.simulationTestId }
                });
              };
              this.button.description = "🎉 ¡El examen está listo! 🎉";
            } else {
              this.button.enabled = false;
              this.button.text = "El examen ya ha finalizado";
              this.button.onclick = () => {
                /**/
              };
              this.button.description =
                "El periodo de participación ya ha finalizado";
            }
          }
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.button.loading = false;
        });
    }
  }
});
