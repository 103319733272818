import history from "@/store/modules/vm/history";
import membership from "@/store/modules/vm/membership";

export default {
  namespaced: true,
  modules: {
    history: history,
    membership: membership
  }
};
