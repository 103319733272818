













































































import Vue from "vue";
import { LoginMethod } from "@/store/modules/user";
import Password from "@/components/Password.vue";

export default Vue.extend({
  name: "Login",
  components: { Password },
  props: {},
  data: () => ({
    valid: false,
    emailRules: [],
    email: "",
    password: "",
    googleLoading: false,
    emailLoading: false,
    LoginMethod: LoginMethod
  }),
  mounted() {
    this.emailRules = [
      v => !!v || this.$i18n.t("components/login/errors/email_empty"),
      v =>
        /.+@.+\..+/.test(v) ||
        this.$i18n.t("components/register/login/email_invalid")
    ];
  },
  methods: {
    actionLoginGoogle(): void {
      this.googleLoading = true;
      this.$store
        .dispatch("user/googleLogin")
        .then(() => {
          this.$emit("login-completed");
        })
        .catch((error: string) => {
          this.showError();
        })
        .finally(() => {
          this.googleLoading = false;
        });
    },
    actionLoginEmail(): void {
      this.emailLoading = true;
      this.$store
        .dispatch("user/emailLogin", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$emit("login-completed");
        })
        .catch((error: string) => {
          this.showError();
        })
        .finally(() => {
          this.emailLoading = false;
        });
    },
    showError(): void {
      this.$store.commit("showMessage", {
        text: this.$t("components/login/errors/general"),
        color: "error"
      });
    }
  }
});
