<template>
  <v-row class="fill-height">
    <v-col>
      <br>
      <br>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn v-if="userIsAdmin" color="primary" dark class="mr-4" @click="dialog = true">
            Agregar
          </v-btn>
          <v-menu bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                class="mr-4"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Día</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 días</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <br>
          <v-btn outlined class="mr-4" @click="setToday">
            Hoy
          </v-btn>
          <v-spacer />
          <v-btn fab text small @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="800">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :event-margin-bottom="3"
          :now="today"
          :type="type"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          locale="es"
          :short-weekdays="false"
          :first-interval="8"
          :interval-minutes="60"
          :interval-count="14"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        />

        <!-- Modal Agregar Evento -->
        <v-dialog v-model="dialog">
          <v-card>
            <v-card-title class="black--text m-2">
              Nueva clase en directo
            </v-card-title>
            <v-container>
              <v-form @submit.prevent="addEvent">
                <v-text-field
                  v-model="name" type="text" label="Título de la clase"
                />
                <v-text-field
                  v-model="details" type="text" label="Enlace a clase"
                />
                <v-text-field
                  v-model="professor" type="text" label="Nombre del profesor"
                />
                <v-text-field
                  v-model="start" type="datetime-local" label="Inicio del evento"
                />
                <v-text-field
                  v-model="end" type="datetime-local" label="Fin del evento"
                />
                <v-select
                  v-model="category"
                  :items="cats"
                  label="Categoría"
                  persistent-hint
                  return-object
                  single-line
                />
                <v-btn type="submit" color="primary" class="mr-4"
                       @click.stop="dialog = false"
                >
                  Agregar
                </v-btn>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>

        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn v-if="userIsAdmin" icon @click="deleteEvent(selectedEvent)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name" />
              <v-spacer />
            </v-toolbar>


            <v-card-text>
              <v-form v-if="currentlyEditing !== selectedEvent.id">
                <div v-if="selectedEvent.professor" style="padding: 10px;">
                  Profesor: {{ selectedEvent.professor }}
                  <br>
                </div>
                <v-btn
                  :href="selectedEvent.details" target="_blank"
                  color="light-blue darken-4"
                  class="ma-2 white--text"
                >
                  Ir a clase
                  <v-icon right dark>
                    mdi-link
                  </v-icon>
                </v-btn>
              </v-form>
              <v-form v-else>
                <v-text-field
                  v-model="selectedEvent.name" type="text"
                  label="Editar Nombre"
                />

                <v-text-field
                  v-model="selectedEvent.details"
                  type="text"
                  label="Enlace"
                />
                <v-text-field
                  v-model="selectedEvent.professor" type="text" label="Nombre del profesor"
                />
                <v-text-field
                  v-model="selectedEvent.start" type="datetime-local" label="Inicio del evento"
                />
                <v-text-field
                  v-model="selectedEvent.end" type="datetime-local" label="Fin del evento"
                />
                <v-text-field
                  v-model="selectedEvent.color" type="color" label="Color del evento"
                />
              </v-form>
            </v-card-text>


            <v-card-actions v-if="userIsAdmin">
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
              <v-btn v-if="currentlyEditing !== selectedEvent.id" text
                     @click.prevent="editEvent(selectedEvent.id)"
              >
                Editar
              </v-btn>

              <v-btn v-else text @click.prevent="updateEvent(selectedEvent)">
                Guardar Cambios
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import * as firebase from "firebase/app";

export default {
  data: () => ({
    today: (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().substr(0, 18),
    focus: (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().substr(0, 18),
    type: '4day',
    typeToLabel: {
      month: 'Mes',
      week: 'Semanal',
      day: 'Día',
      '4day': '4 Días',
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    name: null,
    professor: null,
    details: null,
    color: '#1976D2',
    category: null,
    dialog: false,
    cats: ['CEIS', 'Generalitat de Catalunya', 'Abierto'],
    categories: [{display_name: 'CEIS', hex_color: '#00FF00'}],
    currentlyEditing: null,
    userIsAdmin: "false"
  }),
  computed: {
    title() {
      const {start, end} = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.getEvents();
    this.getAdmin();
  },
  methods: {
    async updateEvent(ev) {
      try {
        await firebase.firestore().collection('calendar-events').doc(ev.id).update({
          name: ev.name,
          details: ev.details,
          professor: ev.professor,
          color: ev.color,
          start: ev.start,
          end: ev.end
        })
        this.selectedOpen = false;
        this.currentlyEditing = null;


      } catch (error) {
        console.log(error);
      }
    },
    editEvent(id) {
      this.currentlyEditing = id
    },
    async deleteEvent(ev) {
      try {
        await firebase.firestore().collection('calendar-events').doc(ev.id).delete();
        this.selectedOpen = false;
        this.getEvents();
      } catch (error) {
        console.log(error);
      }
    },
    async addEvent() {
      try {
        if (this.name && this.start) {
          await firebase.firestore().collection('calendar-events').add({
            name: this.name,
            details: this.details,
            start: this.start,
            end: this.end,
            color: this.color,
            professor: this.professor
          })

          this.getEvents();

          this.name = null;
          this.details = null;
          this.start = null;
          this.end = null;
          this.professor = null;
          this.color = '#1976D2';
        } else {
          console.log('Campos obligatorios');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEvents() {
      try {
        const snapshot = await firebase.firestore().collection('calendar-events').get();
        const events = [];

        snapshot.forEach(doc => {
          // console.log(doc.id);
          const eventoData = doc.data();
          eventoData.id = doc.id;
          events.push(eventoData);
        });
        this.events = events;
      } catch (error) {
        console.log(error);
      }
    },
    getAdmin() {
      firebase.firestore().collection('resources').doc('admin-users')
          .get()
          .then((doc) => {
              //console.log("id", this.$store.state.user.id)
              const found = doc.data().list.includes(this.$store.state.user.id);
              //console.log('found', found)
              this.userIsAdmin = found;
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    }
    ,
    getEventColor(event) {
      return event.color
    }
    ,
    setToday() {
      this.focus = this.today
    }
    ,
    prev() {
      this.$refs.calendar.prev()
    }
    ,
    next() {
      this.$refs.calendar.next()
    }
    ,
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }
    ,
    updateRange({start}) {
      // You could load events from an outside source (like database) now that we have the start  on the calendar
      this.start = start
    }
    ,
    nth(d) {
      return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    }
    ,
  },
}
</script>