import Product from "@/models/product/interface";
import Price from "@/models/price/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";

export default function firebaseToProduct(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >,
  prices: Array<Price>
): Product {
  const data = document.data();
  return new Product(
    document.id,
    data.active,
    data.description,
    data.images,
    data.name,
    data.role,
    prices
  );
}
