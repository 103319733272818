





























































































































































import Vue from "vue";
import * as firebase from "firebase/app";
export default Vue.extend({
  name: "Deck",
  props: {
    deckId : {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      cards: [],
      newCardFront: '',
      newCardBack: '',
      newCardDetail: '',
      newCardDialog: false,
      cardIdBeingEdited: {'front': '', 'back': ''},
      editCardDialog: false,
    };
  },
  created() {
    this.getDeckCards().then(cards => {
      this.cards = cards;
      this.cards.sort((a, b) =>
        a.created.toDate() > b.created.toDate() ? -1 : 1
      );
      this.cards.unshift({'front': '+'});
      this.loading = false;
    }).catch((e) => {
      console.log(e);
      this.loading = false;
    });
  },
  methods: {
    openNewCardDialog() {
      this.newCardDialog = true;
    },
    submitCard() {
      const newCard = {
          front: this.newCardFront,
          back: this.newCardBack,
          detail: this.newCardDetail,
          created: new Date(),
          status: 'unseen',
          deck: this.deckId,
          archived: false,
          author: 'User',
          history: {}
         }
      firebase.firestore().collection('users').doc(this.$store.state.user.id).collection('flashcards').add(newCard)
      .then(docRef => this.cards.splice(1, 0, {'id': docRef.id, ...newCard}));
        
      
      this.newCardFront = this.newCardBack = this.newCardDetail = '';
    },
    getDeckCards() {
      if (this.deckId != 'Todas') {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("users")
            .doc(this.$store.state.user.id)
            .collection("flashcards")
            .where('deck', '==', this.deckId)
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("users")
            .doc(this.$store.state.user.id)
            .collection("flashcards")
            .limit(100)
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
  },
  editCard(e, card) {
    this.cardIdBeingEdited = card;
    this.cardsBak = JSON.stringify(this.cards);
    this.editCardDialog = true;
  },
  onCardEditConfirmation() {
    this.editCardDialog = false;
    const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id).collection('flashcards').doc(this.cardIdBeingEdited.id);
        docRef.update({
          front: this.cardIdBeingEdited.front,
          back: this.cardIdBeingEdited.back,
          detail: this.cardIdBeingEdited.detail
        })
  },
  onContentEditCancel() {
    this.cards = JSON.parse(this.cardsBak);
    this.editCardDialog = false;
  },
  deleteCardById() {
    const id = this.cardIdBeingEdited.id;
    const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id).collection('flashcards').doc(id);
      docRef.delete().then(() => {
        this.$store.commit("showMessage", {
                text: "Tarjeta borrada correctamente",
                color: "success"
          });
          this.cards = this.cards.filter(c => c.id != id);
          this.editCardDialog = false;
      }).catch((error) => {
          this.$store.commit("showMessage", {
                text: "Error borrando tarjeta",
                color: "error"
          });
      });
  },
  deleteDeck() {
    if (confirm('¿Seguro que quieres eliminar el mazo? Esta acción no se puede deshacer')) {
      this.cards.forEach(card => {
          if (card.front != '+')
            firebase.firestore().collection('users')
                .doc(this.$store.state.user.id)
                .collection('flashcards').doc(card.id).delete();
      });
      this.cards = [];
      this.getDecks().then(decks =>{
        const filteredDecks = decks.filter(deck => deck != this.deckId);
        const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id);
        docRef.update({
          decks: filteredDecks
        }).then(
          this.$store.commit("showMessage", {
                text: "Mazo borrado correctamente",
                color: "success"
          })
        )
        this.$router.push({ name: 'flashcards'})
      })

    }
  },
  getDecks() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(this.$store.state.user.id)
        .get()
        .then(doc => {
          if (doc.exists) {
            const decks = doc.data().decks;
            resolve(decks);
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  initRepaso() {
    this.$router.push({ name: 'repaso', params: { deckId: this.deckId } })
  }
  }
});
