const OPEN = 0; // All registered users
const ONLY_PURCHASERS = 1; // Only users that paid for the simulation
const PURCHASERS_AND_PREMIUM = 2; // Members of the academy and payers
const ONLY_PREMIUM = 3; // Only members of the academy

export const SimulationMode = {
  OPEN: OPEN,
  ONLY_PURCHASERS: ONLY_PURCHASERS,
  PURCHASERS_AND_PREMIUM: PURCHASERS_AND_PREMIUM,
  ONLY_PREMIUM: ONLY_PREMIUM
};

export type SimulationMode = number;