import UserSubmission from "@/models/user_submission/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";

export default function firebaseToUserSubmission(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >
): UserSubmission {
  const data = document.data();
  return {
    submissionId: document.id,
    userId: data.userId,
    started: data.started,
    ended: data.ended,
    answers: data.answers,
    testId: data.testId,
    testName: data.testName,
    position: data.position,
    grade: data.grade
  };
}
