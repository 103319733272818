import Price from "@/models/price/interface";

export default class Product {
  active: boolean;
  description: string;
  images: Array<string>;
  name: string;
  role: string;
  id: string;
  prices: Array<Price>;

  constructor(
    id: string,
    active: boolean,
    description: string,
    images: string[],
    name: string,
    role: string,
    prices: Price[]
  ) {
    this.active = active;
    this.description = description;
    this.images = images;
    this.name = name;
    this.role = role;
    this.id = id;
    this.prices = prices;
  }

  getDefaultPrice(): Price {
    // TODO: Handle case where there is no active price
    return this.prices.filter(f => f.interval == "month" && f.intervalCount == 1 && f.active)[0];
  }

  getYearlyPrice(): Price {
    return this.prices.filter(f => f.interval == "year" && f.active)[0];
  }

  getSemesterPrice(): Price {
    return this.prices.filter(f => f.interval == "month" && f.intervalCount == 6 && f.active)[0];
  }
}
