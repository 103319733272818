import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#090d1A",
        secondary: "#607d8b",
        accent: "#C2292F",
        error: "#ff5722",
        warning: "#ff9800",
        info: "#2196f3",
        success: "#8bc34a"
      }
    }
  }
});
