<template>
  <div class="post_list_item">
    <v-container>
      <v-row no-gutters>
        <!-- <v-col :cols="2">
          <div class="respuestas-container">
            <div class="text-h5">
              {{ numComments }}
            </div>
            <div class="text-caption">
              RESPUESTAS
            </div>
          </div>
        </v-col> -->
        <v-col>
          <v-row no-gutters>
            <router-link
              v-if="subforum == 'madrid'"
              :to="{
                name: 'cm_post',
                params: { postId: postId }
              }"
            >
              <div class="reduced_title">{{ title }}</div>
            </router-link>
            <router-link
              v-else-if="subforum == 'gencat'"
              :to="{
                name: 'gencat_post',
                params: { postId: postId }
              }"
            >
              <div class="reduced_title">{{ title }}</div>
            </router-link>

            <router-link
              v-else
              :to="{
                name: 'post',
                params: { postId: postId }
              }"
            >
              <div class="reduced_title">{{ title }}</div>
            </router-link>
          </v-row>
          <v-row no-gutters justify="space-between">
            <v-col md="auto">
              <v-chip
                v-for="tag in tags"
                :key="tag"
                class="ma-2"
                label
                small
                color="accent"
              >
                {{ tag }}
              </v-chip>
            </v-col>
            <v-col>
              <div class="publicado_en" style="float:right;">
                <small>
                  {{ formatDate(created) }}
                  <strong>{{ authorDisplayName }}</strong>
                </small>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <div>
      <small>
        <div v-if="!updated" style="display: inline">
          Publicado {{ formatDate(created) }}
        </div>
        <div v-else style="display: inline">
          Editado {{ formatDate(updated) }}
        </div>
        · <strong>{{ authorDisplayName }}</strong>
      </small>
    </div>
    <v-divider /> -->
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "PostItem",
  // TODO: Instead of passing individual props, pass Post
  props: {
    postId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    authorDisplayName: {
      type: String,
      required: true
    },
    numComments: {
      type: Number,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    created: {
      type: Object,
      required: true
    },
    updated: {
      type: Object,
      required: false,
      default: null
    },
    upvotes: {
      type: Array,
      required: true
    },
    pinned: {
      type: Boolean,
      required: true
    },
    subforum: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      end: "end"
    };
  },
  methods: {
    formatDate(date) {
      if (!("toLocaleDateString" in date)) {
        return this.enhancedFormatDate(date.toDate());
      } else return this.enhancedFormatDate(date);
    },
    enhancedFormatDate(date) {
      const a = date;
      const b = new Date();
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      //console.log('Date: ', date);
      let result = 'Hace ';
      if (diffDays == 0) result = 'hoy';
      else if (diffDays == 1)  result += ' 1 día';
      else if (1 < diffDays && diffDays < 7)   result += ' ' + diffDays + ' días';
      //console.log('Days: ', diffDays);
      
      const diffWeeks = Math.floor(diffDays / 7);
      if (diffWeeks == 1) result += ' 1 semana';
      else if (1 < diffWeeks && diffWeeks < 4) result += ' ' + diffWeeks + ' semanas';
      //console.log('Weeks: ', diffWeeks);

      const diffMonths = Math.floor(diffWeeks / 4);
      if (diffMonths == 1) result += ' 1 mes';
      else if (1 < diffMonths && diffMonths < 12) result += ' ' + diffMonths + ' meses';
      //console.log('Months: ', diffMonths);

      const diffYears = Math.floor(diffMonths / 12);
      if (diffYears == 1) result += ' 1 año';
      else if (1 < diffYears) result += ' ' + diffYears + ' años';
      //console.log('Years: ', diffYears);
      //console.log('Result: ', result);

      return result;
    }
  }
});
</script>

<style scoped>
.post_list_item {
  background: lightgray;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 3px;
  overflow: auto;
  border-radius: 6px;
}

.reduced_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-left: 5px;
}

.reduced_body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.respuestas-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.publicado_en {
  height: 100%;
  display: flex;
  align-items: flex-end;
  float: right;
}

a {
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 400;
}
</style>
