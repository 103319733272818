import UserMetrics from "@/models/user_metrics/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";

export default function firebaseToUserMetrics(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >
): UserMetrics {
  const data = document.data();
  return {
    numTests: data.metrics.numTests,
    correct: data.metrics.correct,
    wrong: data.metrics.wrong
  };
}

export function firebaseDataToUserMetrics(
  data: firebase.firestore.DocumentData
): UserMetrics {
  return {
    numTests: data.numTests,
    correct: data.correct,
    wrong: data.wrong
  };
}
