






import Vue from 'vue'
import Calendario from "../components/Calendario.vue";
export default Vue.extend({
  name: "Directos",
  components: {
    Calendario
  }
})
