import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import Tests from "@/views/Tests.vue";
import TestCategories from "@/views/TestCategories.vue";
import Landing from "@/views/Landing.vue";
import LandingSimulacro from "@/views/LandingSimulacro.vue";
import Result from "@/views/Result.vue";
import Test from "@/views/Test.vue";
import Viewer from "@/views/Viewer.vue";
import Directos from "@/views/Directos.vue";
import store from "@/store";
import * as firebase from "firebase/app";
import "firebase/auth";
import Forum from "@/views/Forum.vue";
import Register from "@/views/Register.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import NotFound from "@/views/NotFound.vue";
import TestInput from "@/views/TestInput.vue";
import Academia from "@/views/Academia.vue";
import Flashcards from "@/views/Flashcards.vue";
import Repaso from "@/views/Repaso.vue";
import Deck from "@/views/Deck.vue";
// import { State } from "@/store/modules/user";
import { LogLevels } from "@/models/log/interface";
import { PermissionLevel } from "@/models/permissions/interface";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Landing,
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
      titleKey: "routes/landing"
    }
  },
  {
    path: "/academia",
    name: "academia",
    component: Academia,
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
    }
  },
  {
    path: "/directos",
    name: "directos",
    component: Directos,
    meta: {
      requiredPermissions: new Set([PermissionLevel.PREMIUM]),
    }
  },
  {
    path: "/simulacro",
    name: "landing_simulacro",
    component: LandingSimulacro,
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE])
      //title: "Simulacro"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
      titleKey: "routes/login"
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
      titleKey: "routes/register"
    }
  },
  {
    path: "/test_categories",
    name: "test_categories",
    component: TestCategories,
    meta: {
      requiredPermissions: new Set([
        PermissionLevel.REGISTERED
      ]), //TODO:
      titleKey: "routes/tests"
    }
  },
  {
    path: "/tests/:category",
    name: "tests",
    component: Tests,
    meta: {
      requiredPermissions: new Set([
        PermissionLevel.REGISTERED
      ]), //TODO:
      titleKey: "routes/tests"
    },
    props: true
  },
  {
    path: "/result",
    name: "result",
    component: Result,
    meta: {
      requiredPermissions: new Set([
        PermissionLevel.TESTS,
        PermissionLevel.REGISTERED
      ]),
      titleKey: "routes/result"
    },
    props: true
  },
  {
    path: "/test/:testId",
    name: "test",
    component: Test,
    meta: {
      requiredPermissions: new Set([
        PermissionLevel.REGISTERED,
        PermissionLevel.TESTS,
        PermissionLevel.PREMIUM
      ]),
      titleKey: "routes/testExam"
    },
    props: true
  },
  {
    path: "/verifyemail",
    name: "verify_email",
    component: VerifyEmail,
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/verifyemail"
    }
  },
  {
    path: "/reset_password",
    name: "reset_password",
    component: () =>
      import(
        /* webpackChunkName: "reset_password" */ "@/views/ResetPassword.vue"
      ),
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
      titleKey: "routes/reset_password"
    }
  },
  {
    path: "/profile/delete",
    name: "profile_delete",
    component: () =>
      import(
        /* webpackChunkName: "profile_delete" */ "@/views/DeleteAccount.vue"
      ),
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/profile_delete"
    }
  },
  {
    path: "/profile/password",
    name: "profile_password",
    component: () =>
      import(
        /* webpackChunkName: "profile_delete" */ "@/views/ChangePassword.vue"
      ),
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/profile_password"
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/profile"
    }
  },
  {
    path: "/group/:teachingGroupId",
    name: "group",
    component: () =>
      import(/* webpackChunkName: "group" */ "@/views/TeachingGroup.vue"),
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/forum"
    },
    props: true
  },
  {
    path: "/unit/:teachingUnitId",
    name: "unit",
    component: () =>
      import(/* webpackChunkName: "unit" */ "@/views/TeachingUnit.vue"),
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/forum"
    },
    props: true
  },
  {
    path: "/viewer",
    name: "viewer",
    component: Viewer,
    meta: {
      requiredPermissions: new Set([PermissionLevel.REGISTERED]),
      titleKey: "routes/profile"
    },
    props: true
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "@/views/Privacy.vue"),
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
      titleKey: "routes/profile"
    }
  },
  {
    path: "/foro",
    name: "foro",
    component: Forum,
    meta: {
      requiredPermissions: new Set([PermissionLevel.PREMIUM]),
      titleKey: "routes/forum"
    }
  },
  {
    path: "/test_input/:testIdToEdit",
    name: "test_input",
    component: TestInput,
    meta: {
      requiredPermissions: new Set([PermissionLevel.PREMIUM]),
    },
    props: true
  },
  {
    path: "/flashcards",
    name: "flashcards",
    component: Flashcards,
    meta: {
      requiredPermissions: new Set([PermissionLevel.PREMIUM]),
    }
  },
  {
    path: "/deck/:deckId",
    name: "deck",
    component: Deck,
    meta: {
      requiredPermissions: new Set([PermissionLevel.PREMIUM]),
    },
    props: true
  },
  {
    path: "/repaso/:deckId",
    name: "repaso",
    component: Repaso,
    meta: {
      requiredPermissions: new Set([PermissionLevel.PREMIUM]),
    },
    props: true
  },
  // Not Found should be always the last route
  {
    path: "/*",
    name: "not_found",
    component: NotFound,
    meta: {
      requiredPermissions: new Set([PermissionLevel.NONE]),
      titleKey: "routes/not_found"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (isNavigationFailure(err, NavigationFailureType.duplicated)) {
      store.dispatch("log", { level: LogLevels.WARNING, message: err });
    } else if (isNavigationFailure(err, NavigationFailureType.redirected)) {
      store.dispatch("log", { level: LogLevels.WARNING, message: err });
    } else if (isNavigationFailure(err, NavigationFailureType.cancelled)) {
      store.dispatch("log", { level: LogLevels.WARNING, message: err });
    } else {
      throw err;
    }
  });
};

// TODO: Find cleaner way
function getCurrentUserEmailVerified() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      user.reload().then(() => {
        if (user) {
          resolve(user.emailVerified);
        } else {
          resolve(false);
        }
      });
    }, reject);
  });
}

router.beforeEach(async (to, from, next) => {
  // TODO: Find cleaner way
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  //TODO: Find cleaner way
  store
    .dispatch("user/loadFromCookies")
    .catch(error => {
      store.dispatch("log", { level: LogLevels.ERROR, message: error });
    })
    .finally(async () => {
      const roles = store.state.user.roles;
      const hasAnyRequiredLevels = store.getters["user/hasAnyRole"](
        to.meta.requiredPermissions
      );
      if (hasAnyRequiredLevels) {
        next();
      } else {
        const minimumMissingRole = Math.min(
          ...[...to.meta.requiredPermissions].filter(i => !roles.has(i))
        );
        console.log(minimumMissingRole);
        let messageKey = "";
        switch (minimumMissingRole) {
          case PermissionLevel.REGISTERED:
            messageKey = "permission_level_required/registered";
            next({ name: "login", query: { redirect: to.path } });
            break;
          case PermissionLevel.VALIDATED:
            messageKey = "permission_level_required/validated";
            next({ name: "verify_email" });
            break;
          case PermissionLevel.TESTS:
          case PermissionLevel.PREMIUM:
            messageKey = "permission_level_required/subscribed";
            next({ name: "profile" });
            break;
          default:
            break;
        }
        store.commit("showMessage", {
          text: router.app.$t(messageKey),
          color: "error"
        });
      }
    });
});

export default router;
