<template>
  <div class="hero-container">
    <div class="vimeo-wrapper">
      <iframe
        class="blurred"
        src="https://player.vimeo.com/video/596506000?background=1&autoplay=1&loop=1&byline=0&title=0&quality=540p"
        frameborder="0"
      />
    </div>
    <div class="hero-text">
      <!-- <img src="@/assets/web_hi_res_512.png" height="150px" width="150px"> -->
      <div class="hero-title">
        OPOSICIONES BOMBERO
      </div>
      <p style="margin-bottom: 0px; font-size: 22px; font-weight: 300;">
        La academia online hecha por bomberos. Te acompañamos hasta tu primera guardia.
      </p>
      <br>
      <v-btn
        color="#C2292F"
        dark
        large
        style="border-radius: 0px;"
        @click="empiezaHoyButtonAction"
      >
        <strong>{{ buttonText }}</strong>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: 'HeroVideo',
  data() {
    return {
      buttonText: 'EMPIEZA HOY',
    };
  },
  created() {
    if (Object.keys(this.$store.state.user.subscribedSince).length != 0) {
        this.buttonText = "Ir a la academia"
    }
  },
  methods: {
    empiezaHoyButtonAction() {
      // No ha iniciado sesion
      if (this.$store.state.user.token === null) {
        this.$router.push({name: 'register'})
      }
      else if (Object.keys(this.$store.state.user.subscribedSince).length === 0) {
        this.$router.push({name: 'profile'})
      }
      else {
        this.$router.push({name: 'academia'})
      }
    }
  }
});
</script>

<style>
.vimeo-wrapper {
  pointer-events: none;
  overflow: hidden;
}

.blurred {
    -o-filter: blur(4px);
    filter: blur(4px) brightness(40%);
    transform: scale(1.05);
}

.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
}

.hero-text {
  padding: 30px;
  margin-top: -100px;
  text-align: center;
  position: absolute;
  color:white;
  z-index: 2;
}

.hero-container {
    /* background-image: url(../../assets/banner-2-blue.jpg); */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.hero-title {
  font-family: 'Anton', sans-serif;
  font-size: clamp(4em, 7vw, 6em);
}

@media screen and (max-width: 600px) {
.hero-title {
  font-family: 'Anton', sans-serif;
  font-size: 12vw;
}
}
</style>