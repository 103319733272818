import UserSubmission from "@/models/user_submission/interface";
import firebaseToUserSubmission from "@/models/user_submission/mapper";
import * as firebase from "firebase/app";
import "firebase/firestore";

interface UserSubmissionWithRaw extends UserSubmission {
  raw: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >;
}

function userSubmissionToUserSubmissionWithRaw(
  submission: UserSubmission,
  d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): UserSubmissionWithRaw {
  return {
    started: submission.started,
    ended: submission.ended,
    answers: submission.answers,
    testId: submission.testId,
    testName: submission.testName,
    grade: submission.grade,
    position: submission.position,
    userId: submission.userId,
    submissionId: submission.submissionId,
    raw: d
  };
}

interface State {
  testSubmissions: UserSubmissionWithRaw[];
  numElements: number;
}

interface ActionParam {
  state: State;
  commit: (mutation: string, value: any) => void;
  dispatch: (action: string, value?: any, args?: any) => any;
  rootState: any;
}

const state = () => ({
  numElements: 0,
  testSubmissions: []
});

const getters = {};

const actions = {
  onMounted(args: ActionParam): Promise<void> {
    args.commit("setNumElements", 0);
    args.commit("setTestSubmissions", []);
    return args.dispatch("getNumElements").then((numElements: number) => {
      args.commit("setNumElements", numElements);
      return args.dispatch("fetchNextSubmissions", 5);
    });
  },

  fetchNextSubmissions(args: ActionParam, num: number): Promise<void> {
    const userId = args.rootState.user.id;
    let query = firebase
      .firestore()
      .collection("users/" + userId + "/submissions")
      .where("ended", "!=", null)
      .orderBy("ended", "desc")
      .limit(num);
    if (args.state.testSubmissions.length != 0) {
      query = query.startAfter(
        args.state.testSubmissions[args.state.testSubmissions.length - 1].raw
      );
    }
    return new Promise((resolve, reject) => {
      query
        .get()
        .then(documentSnapshots => {
          if (documentSnapshots.empty) {
            args.commit("addTestSubmissions", []);
          } else {
            args.commit(
              "addTestSubmissions",
              documentSnapshots.docs.map(d => {
                const submission = firebaseToUserSubmission(d);
                return userSubmissionToUserSubmissionWithRaw(submission, d);
              })
            );
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getNumElements(args: ActionParam): Promise<number> {
    // TODO: Find more efficient (cheaper method)
    return firebase
      .firestore()
      .collection("users/" + args.rootState.user.id + "/submissions")
      .where("ended", "!=", null)
      .get()
      .then(snap => {
        return snap.size;
      });
    // return new Promise((resolve, reject) => {
    //   args
    //     .dispatch("user/getMetrics", {}, { root: true })
    //     .then(metrics => {
    //       resolve(metrics.numTests);
    //     })
    //     .catch(error => {
    //       reject(error);
    //     });
    // });
  }
};

const mutations = {
  setNumElements(state: State, numElements: number) {
    state.numElements = numElements;
  },

  addTestSubmissions(state: State, testSubmissions: UserSubmissionWithRaw[]) {
    state.testSubmissions = state.testSubmissions.concat(testSubmissions);
  },

  setTestSubmissions(state: State, testSubmissions: UserSubmissionWithRaw[]) {
    state.testSubmissions = testSubmissions;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
