import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import UserMetrics from "@/models/user_metrics/interface";
import firebaseToUserMetrics from "@/models/user_metrics/mapper";
import firebaseToUser from "@/models/user/mapper";
import User from "@/models/user/interface";
import Address from "@/models/address/interface";
import { LogLevels } from "@/models/log/interface";
import { PermissionLevel } from "@/models/permissions/interface";

// export enum Actions {
//   LOAD_FROM_COOKES = "LOAD_FROM_COOKES",
//   GOOGLE_LOGIN = "GOOGLE_LOGIN",
//   EMAIL_LOGIN = "EMAIL_LOGIN",
//   LOGOUT = "LOGOUT",
//   EMAIL_REGISTER = "EMAIL_REGISTER",
//   SEND_VERIFICATION_EMAIL = "SEND_VERIFICATION_EMAIL"
// }

// enum Mutations {
//   SET_TOKEN = "SET_TOKEN",
//   SET_USERNAME = "SET_USERNAME",
//   SET_EMAIL = "SET_EMAIL",
//   SET_ID = "SET_ID"
// }

export enum LoginMethod {
  GOOGLE,
  EMAIL,
  UNDEFINED
}

export interface State {
  token: string | null;
  username: string | null;
  email: string | null;
  id: string | null;
  loginMethod: LoginMethod;
  user: User | null;
  roles: Set<PermissionLevel>;
  subscribedSince: Record<PermissionLevel, Date>;
}

interface ActionParam {
  state: State;
  commit: (mutation: string, value: any) => void;
  dispatch: any;
  rootState: any;
}

const state: State = {
  token: null,
  username: null,
  email: null,
  id: null,
  loginMethod: LoginMethod.UNDEFINED,
  user: null,
  roles: new Set([PermissionLevel.NONE]),
  subscribedSince: {}
};

const getters = {
  hasAnyRole: (state: State) => (roles: Set<PermissionLevel>) => {
    return new Set([...state.roles].filter(i => roles.has(i))).size > 0;
  },

  hasRole: (state: State) => (role: PermissionLevel) => {
    return state.roles.has(role);
  }
};

function getEmptyUser(): User {
  const emptyAddress: Address = {
    city: null,
    country: null,
    line1: null,
    line2: null,
    postalCode: null,
    state: null
  };
  const emptyUserMetrics: UserMetrics = {
    correct: 0,
    wrong: 0,
    numTests: 0
  };
  const emptyUser: User = {
    achievements: [],
    address: emptyAddress,
    birthDate: null,
    expirationBanned: null,
    expirationPremium: null,
    metrics: emptyUserMetrics,
    drivingLicenses: [],
    pastOpos: "",
    nextOpos: "",
    otherAcademies: ""
  };
  return emptyUser;
}

function createEmptyUser(id: string) {
  const emptyUser = getEmptyUser();
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .set(emptyUser)
    .then(() => {
      // console.log("HC: Created empty user");
    })
    .catch(error => {
      // console.error("Error adding document: ", error);
    });
}

function getUserAndCreateIfNotExists(id: string): Promise<User> {
  return firebase
    .firestore()
    .collection("users")
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        return firebaseToUser(doc);
      } else {
        createEmptyUser(id);
        return getEmptyUser();
      }
    });
}

function getEmptyState(): State {
  const newState: State = {
    token: null,
    username: null,
    email: null,
    id: null,
    loginMethod: LoginMethod.UNDEFINED,
    user: null,
    roles: new Set([PermissionLevel.NONE]),
    subscribedSince: {}
  };
  return newState;
}

function postUserAccess(state: State, args: ActionParam): Promise<void> {
  return getUserAndCreateIfNotExists(state.id)
    .then((user: User) => {
      state.user = user;
      args.commit("setState", state);
    })
    .then(args.dispatch("checkUserOwnsProduct", "prod_IXHz15wuPIQkdk"))
    .then(args.dispatch("checkUserOwnsProduct", "prod_Ivs4JoEyOWbUtE"))
    .then(args.dispatch("checkUserOwnsProduct", "prod_IvsEyrpIAzQEJr"));
}

function productIdToRole(productId: string): PermissionLevel {
  let level: PermissionLevel = PermissionLevel.NONE;
  switch (productId) {
    case "prod_IXHz15wuPIQkdk":
      level = PermissionLevel.PREMIUM;
      break;
    case "prod_Ivs4JoEyOWbUtE":
      level = PermissionLevel.TESTS;
      break;
    case "prod_IvsEyrpIAzQEJr":
      level = PermissionLevel.MADRID;
      break;
    default:
      level = PermissionLevel.NONE;
  }
  return level;
}

const actions = {
  loadFromCookies(args: ActionParam): Promise<State> {
    return new Promise((resolve, reject) => {
      const newState: State = getEmptyState();
      for (const property in newState) {
        newState[property] = Vue.$cookies.get(property);
      }
      // TODO:
      if (newState.loginMethod === null) {
        newState.loginMethod = LoginMethod.UNDEFINED;
      }
      if (newState.roles === null) {
        newState.roles = new Set([PermissionLevel.NONE]);
      } else {
        newState.roles = new Set(
          ((newState.roles as unknown) as string).split(",").map(Number)
        );
      }
      [
        PermissionLevel.PREMIUM,
        PermissionLevel.MADRID,
        PermissionLevel.TESTS
      ].forEach(function(role) {
        if (newState.roles.has(role)) {
          newState.subscribedSince[role] = new Date(
            Vue.$cookies.get("subscribedSince")[role.toString()]
          );
        }
      });
      if (newState.id) {
        getUserAndCreateIfNotExists(newState.id)
          .then((user: User) => {
            newState.user = user;
            args.commit("setState", newState);
            resolve(newState);
          })
          .then(() => {
            args.dispatch("checkUserOwnsProduct", "prod_IXHz15wuPIQkdk");
            args.dispatch("checkUserOwnsProduct", "prod_Ivs4JoEyOWbUtE");
            args.dispatch("checkUserOwnsProduct", "prod_IvsEyrpIAzQEJr");
          })
          .catch(error => {
            args.commit("setState", newState);
            reject(error);
          });
      } else {
        resolve(newState);
      }
    });
  },

  googleLogin(args: ActionParam): Promise<void> {
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          const credential: firebase.auth.OAuthCredential = result.credential!;
          const token = credential.accessToken!;
          const user = result.user!;
          const newState: State = {
            token: token,
            username: user.displayName,
            email: user.email,
            id: user.uid,
            loginMethod: LoginMethod.GOOGLE,
            user: null,
            roles: new Set([
              PermissionLevel.NONE,
              PermissionLevel.REGISTERED,
              PermissionLevel.VALIDATED
            ]),
            subscribedSince: {}
          };
          args.commit("setState", newState);
          return newState;
        })
        .then((state: State) => postUserAccess(state, args))
        .then(resolve)
        .catch(function(error) {
          const errorMessage = error.message;
          reject(errorMessage);
        });
    });
  },

  emailLogin(
    args: ActionParam,
    credentials: { email: string; password: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(result => {
          const user = result.user!;
          const newState: State = {
            token: user.refreshToken,
            username: user.displayName,
            email: user.email,
            id: user.uid,
            loginMethod: LoginMethod.EMAIL,
            user: null,
            roles: new Set([PermissionLevel.NONE, PermissionLevel.REGISTERED]),
            subscribedSince: {}
          };
          args.commit("setState", newState);
          return newState;
        })
        .then((state: State) => postUserAccess(state, args))
        .then(resolve)
        .catch(error => {
          const errorMessage = error.message;
          reject(errorMessage);
        });
    });
  },

  logout(args: ActionParam) {
    firebase.auth().signOut();
    const newState: State = getEmptyState();
    args.commit("setState", newState);
  },

  emailRegister(
    args: ActionParam,
    credentials: { displayName: string; email: string; password: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(credentials.email, credentials.password)
        .then(result => {
          const user = result.user!;
          user.sendEmailVerification();
          user.updateProfile({
            displayName: credentials.displayName,
            photoURL: null
          });
          const newState: State = {
            token: user.refreshToken,
            username: credentials.displayName,
            email: user.email,
            id: user.uid,
            loginMethod: LoginMethod.EMAIL,
            user: null,
            roles: new Set([PermissionLevel.NONE, PermissionLevel.REGISTERED]),
            subscribedSince: {}
          };
          args.commit("setState", newState);
          return newState;
        })
        .then((state: State) => postUserAccess(state, args))
        .then(resolve)
        .catch(error => {
          const errorCode = error.code;
          reject(errorCode);
        });
    });
  },

  sendVerificationEmail(args: ActionParam): Promise<void> {
    return firebase.auth().currentUser.sendEmailVerification();
  },

  delete(args: ActionParam): Promise<void> {
    // TODO: it will not delete the subcollections from the user. Should it?
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(args.state.id)
        .delete()
        .then(() => {
          // console.log("Document successfully deleted!");
        })
        .catch(error => {
          // console.error("Error removing document: ", error);
        });
      firebase
        .auth()
        .currentUser.delete()
        .then(() => {
          args.dispatch("logout");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updatePassword(args: ActionParam, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.updatePassword(password)
        .then(() => {
          resolve();
        })
        .catch(error => {
          const errorCode = error.code;
          reject(errorCode);
        });
    });
  },

  sendRecoverEmail(args: ActionParam, email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  },

  // TODO: Update state.user.metrics with this
  getMetrics(args: ActionParam): Promise<UserMetrics> {
    return firebase
      .firestore()
      .collection("users")
      .doc(args.state.id)
      .get()
      .then(doc => {
        if (doc.exists) {
          return firebaseToUserMetrics(doc);
        } else {
          throw "Document does not exist";
        }
      })
      .catch(error => {
        args.dispatch(
          "log",
          { level: LogLevels.ERROR, message: error },
          { root: true }
        );
        throw error;
      });
  },

  setUser(args: ActionParam, user): Promise<User> {
    let birthTimestamp: firebase.firestore.Timestamp = null;
    if (user.birthDate) {
      birthTimestamp = firebase.firestore.Timestamp.fromDate(user.birthDate);
    }
    return firebase
      .firestore()
      .collection("users")
      .doc(args.state.id)
      .update({ ...user, ...{ birthDate: birthTimestamp } })
      .then(() => {
        const updatedUser = { ...args.state.user, ...user };
        const updatedState = { ...args.state };
        updatedState.user = updatedUser;
        args.commit("setState", updatedState);
        return updatedUser;
      });
  },

  checkUserOwnsProduct(args: ActionParam, productId: string): Promise<boolean> {
    const productRef = firebase
      .firestore()
      .collection("products")
      .doc(productId);
    return firebase
      .firestore()
      .collection("users/" + args.state.id + "/subscriptions")
      .where("status", "in", ["active", "trialing"])
      .where("product", "==", productRef)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          const updatedState = { ...args.state };
          const level = productIdToRole(productId);
          updatedState.roles.delete(level);
          args.commit("setState", updatedState);
          return false;
        } else {
          const updatedState = { ...args.state };
          const level = productIdToRole(productId);
          updatedState.roles.add(level);
          args.commit("setState", updatedState);
          args.dispatch("checkSubscriptionStartDate", productId);
          return true;
        }
      });
  },

  checkSubscriptionStartDate(
    args: ActionParam,
    productId: string
  ): Promise<Date> {
    const productRef = firebase
      .firestore()
      .collection("products")
      .doc(productId);
    return firebase
      .firestore()
      .collection("users/" + args.state.id + "/subscriptions")
      .where("status", "in", ["active", "trialing"])
      .where("product", "==", productRef)
      .limit(1)
      .get()
      .then(function(querySnapshot) {
        if (querySnapshot.empty) {
          return null;
        } else {
          const result = [];
          const subscribedSinceDate = querySnapshot.docs[0]
            .data()
            .created.toDate();
          const updatedState = { ...args.state };
          updatedState.subscribedSince[
            productIdToRole(productId)
          ] = subscribedSinceDate;
          args.commit("setState", updatedState);
        }
      });
  },

};

function setCookie(name: string, object: unknown) {
  if (object === null) {
    Vue.$cookies.remove(name);
  } else if (object instanceof Set) {
    Vue.$cookies.set(name, Array.from(object));
  } else {
    Vue.$cookies.set(name, object);
  }
}

// TODO: Refactor all mutation  to capitals SET_STATE
const mutations = {
  setState(state: State, newState: State) {
    for (const property in newState) {
      setCookie(property, newState[property]);
      state[property] = newState[property];
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
