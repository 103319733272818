import * as firebase from "firebase/app";
import "firebase/firestore";
import TeachingGroup from "@/models/teaching_group/interface";
import TeachingUnit from "@/models/teaching_unit/interface";
import firebaseToTeachingUnit from "@/models/teaching_unit/mapper";

export default function firebaseToTeachingGroup(
  documents: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  teachingGroupId: string
): TeachingGroup {
  const teaching_units = Array<TeachingUnit>();
  documents.forEach(function(doc) {
    teaching_units.push(firebaseToTeachingUnit(doc));
  });
  return {
    teachingUnits: teaching_units,
    teachingGroupId: teachingGroupId
  };
}
