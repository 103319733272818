














import Vue from "vue";

export default Vue.extend({
  name: "Login",
  props: {
    password: {
      required: true,
      default: "",
      type: String
    },
    rules: {
      required: false,
      default: function() {
        return [v => !!v || this.$t("components/login/errors/password_empty")];
      }
    }
  },
  data() {
    return {
      visible: false,
      myPassword: this.password
    };
  },
  watch: {
    myPassword(newValue: string) {
      this.$emit("update:password", newValue);
    }
  }
});
