



























































































































import { LogLevels } from "@/models/log/interface";

export default {
  name: "Register",
  components: {},
  data() {
    return {
      valid: false,
      emailRules: [
        v => !!v || this.$i18n.t("components/register/errors/email_empty"),
        v =>
          /.+@.+\..+/.test(v) ||
          this.$i18n.t("components/register/errors/email_invalid")
      ],
      passwordConfirmationRules: [
        v => !!v || this.$i18n.t("components/register/errors/password_empty"),
        v =>
          v === this.password ||
          this.$i18n.t("views/register/errors/password_mismatch")
      ],
      email: "",
      password: "",
      passwordConfirmation: "",
      firstname: "",
      lastname: "",
      googleLoading: false,
      emailLoading: false
    };
  },
  methods: {
    actionLoginGoogle(): void {
      this.googleLoading = true;
      this.$store
        .dispatch("user/googleLogin")
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((error: string) => {
          this.showError(error);
        })
        .finally(() => {
          this.googleLoading = false;
        });
    },
    actionRegisterEmail(): void {
      this.emailLoading = true;
      const displayName = this.firstname + " " + this.lastname;
      this.$store
        .dispatch("user/emailRegister", {
          displayName: displayName,
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: "verify_email" });
        })
        .catch((error: string) => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
          this.showError(error);
        })
        .finally(() => {
          this.emailLoading = false;
        });
    },
    showError(error: string): void {
      let textError;
      switch (error) {
        case "auth/weak-password":
          textError = this.$t("components/register/errors/password_weak");
          break;
        case "auth/email-already-in-use":
          textError = this.$t("views/register/erros/email_used");
          break;
        default:
          textError = this.$t("components/register/errors/general");
          break;
      }
      this.$store.commit("showMessage", { text: textError, color: "error" });
    }
  }
};
