<template>
  <div
    v-if="!fullScreen"
    style="color: white;"
    :class="
      $vuetify.breakpoint.mobile ? 'mobile-container' : 'desktop-container'
    "
  >
    <div class="text-h5 text-sm-h3 lesson_title">
      {{ title }}
    </div>
    <div class="iframe-container">
      <iframe
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        :src="embedUrl"
      />
    </div>
  </div>
  <iframe
    v-else
    :src="embedUrl"
    height="100%"
    width="100%"
    frameborder="0"
    allowfullscreen
    webkitallowfullscreen
    mozallowfullscreen
  >
  </iframe>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "Viewer",
  props: {
    title: {
      type: String,
      required: true,
      default: "Física 1"
    },
    embedUrl: {
      type: String,
      required: true,
      default:
        "https://drive.google.com/file/d/1099p21eBE8VuWaINcpbpHw6PmobgwpQB/preview"
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lesson_title {
  margin-bottom: 40px;
}
.desktop-container {
  padding-top: 80px;
  padding-left: 350px;
  padding-right: 350px;
  padding-bottom: 100px;
}

.mobile-container {
  padding: 40px;
}
</style>
