import * as firebase from "firebase/app";
import "firebase/firestore";
import TeachingUnit from "@/models/teaching_unit/interface";

export default function firebaseToTeachingUnit(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >
): TeachingUnit {
  const data = document.data();
  try {
  return {
    id: document.id,
    title: data.title,
    documents: data.documents,
    lessons: data.lessons,
    links: data.links,
    publishingDate: data.publishingDate.toDate(),
    expirationDate: data.expirationDate.toDate(),
    updated: data.updated.toDate(),
    tests: data.tests,
    hidden: data.hidden,
    freeToRegistered: data.freeToRegistered,
    category: data.category,
    professor: data.professor
  };
  } catch (error) {
    console.log(data);
  }
}
