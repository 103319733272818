


















































































import Vue from "vue";
import PrecioLanding from "../components/landing/PrecioLanding.vue";
import PonentesLanding from "../components/landing/PonentesLanding.vue";
import FaqLanding from "@/components/landing/FaqLanding.vue";
import SliderLanding from "@/components/landing/SliderLanding.vue";
import HeroVideo from "@/components/landing/HeroVideo.vue";
import OurContent from "@/components/landing/OurContent.vue";
import NewsletterSubscribe from "@/components/landing/NewsletterSubscribe.vue";
import SubscriptionNeeded from "@/components/SubscriptionNeeded.vue";
import AlumniTestimonials from "@/components/landing/AlumniTestimonials.vue";
export default Vue.extend({
  components: {
    PrecioLanding,
    PonentesLanding,
    FaqLanding,
    SliderLanding,
    HeroVideo,
    OurContent,
    NewsletterSubscribe,
    SubscriptionNeeded,
    AlumniTestimonials,
  },
  data() {
    return {
      subNeededDialog: false,
    };
  },
  methods: {},
});
