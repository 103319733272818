<template>
  <div class="carousel-info">
    <div
      class="pa-10 section-title"
      :style="
        $vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size: 38px;'
      "
    >
      ¿Qué opinan nuestros alumnos?
    </div>
    <v-carousel :height="$vuetify.breakpoint.smAndDown ? '50vh' : '45vh'">
      <v-carousel-item
        v-for="review in reviews"
        :key="review.title"
        style="height: 100%"
      >
        <div style="height: 100%">
          <v-card class="card-item" color="white">
            <v-card-title class="black--text mb-2 mt-2">
              {{ review.title }}
            </v-card-title>
            <v-card-text
              class="carousel-subtitle font-italic black--text mb-2 mt-2"
            >
              "{{ review.review }}"
            </v-card-text>
            <v-card-text
              class="black--text"
              style="font-size: 15px; font-weight: bold; padding: 0;"
            >
              {{ review.display_name }}
            </v-card-text>
            <v-card-text class="black--text mb-2 mt-2">
              <span style="font-size: 16px; font-weight: bold;"
                >{{ review.antiquity }} meses
              </span>
              en Oposiciones Bombero
            </v-card-text>
          </v-card>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import * as firebase from "firebase/app";
export default {
  name: "SliderLanding",
  data: () => ({
    reviews: [
      {
        title: "Grandioso",
        review:
          "Gran academia y mejores ponentes. Gran academia y mejores ponentes.Gran academia y mejores ponentes Gran academia y mejores ponentes",
        reviewed_at: "20/05/2021",
        display_name: "Agapito Rodriguez",
        antiquity: "5",
        hidden: true,
      },
      {
        title: "Excelente",
        review:
          "Gran academia y mejores ponentes. Gran academia y mejores ponentes.Gran academia y mejores ponentes Gran academia y mejores ponentes",
        reviewed_at: "20/05/2021",
        display_name: "Agapito Rodriguez",
        antiquity: "5",
        hidden: true,
      },
      {
        title: "Lo mejor sin duda, lo mejor",
        review:
          "Gran academia y mejores ponentes Gran academia y mejores ponentes. Gran academia y mejores ponentes.Gran academia y mejores ponentes Gran academia y mejores ponentes",
        reviewed_at: "20/05/2021",
        display_name: "Agapito Rodriguez",
        antiquity: "5",
        hidden: true,
      },
      {
        title: "Lo mejor para la oposición",
        review:
          "Gran academia y mejores ponentes Gran academia y mejores ponentes. Gran academia y mejores ponentes.Gran academia y mejores ponentes Gran academia y mejores ponentes",
        reviewed_at: "20/05/2021",
        display_name: "Agapito Rodriguez",
        antiquity: "5",
        hidden: true,
      },
      {
        title: "No hay nada como Academia Bomberos",
        review:
          "Gran academia y mejores ponentes Gran academia y mejores ponentes. Gran academia y mejores ponentes.Gran academia y mejores ponentes Gran academia y mejores ponentes",
        reviewed_at: "20/05/2021",
        display_name: "Agapito Rodriguez",
        antiquity: "5",
        hidden: true,
      },
    ],
  }),
  created() {
    this.getReviews().then((reviews) => {
      this.reviews = reviews;
    });
  },
  methods: {
    getReviews() {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("resources")
          .doc("landing-content")
          .get()
          .then((doc) => {
            if (doc.exists) {
              resolve(doc.data().user_reviews);
            } else {
              reject("Document does not exist");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.titulo-slider {
  color: white;
  text-align: center;
  font-size: 28.8px;
}

.carousel {
  max-width: 75vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}
.carousel-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.carousel-subtitle {
  max-width: 75%;
}
</style>
