import Address from "@/models/address/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";

export function firebaseToAddress(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >
): Address {
  const data = document.data();
  return {
    city: data.city,
    country: data.country,
    line1: data.line1,
    line2: data.line2,
    postalCode: data.postalCode,
    state: data.state
  };
}

export function firebaseDataToAddress(
  data: firebase.firestore.DocumentData
): Address {
  return {
    city: data.city,
    country: data.country,
    line1: data.line1,
    line2: data.line2,
    postalCode: data.postalCode,
    state: data.state
  };
}
