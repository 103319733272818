import Product from "@/models/product/interface";

interface State {
  products: Array<Product>;
}

interface ActionParam {
  state: State;
  commit: (mutation: string, value: any) => void;
  dispatch: (action: string, value?: any, args?: any) => any;
  rootState: any;
}

// TODO: Extract this key to build environment
const state = () => ({
  products: []
});

const getters = {};

const actions = {
  onMounted(args: ActionParam): Promise<void> {
    return args
      .dispatch("payment/getProducts", {}, { root: true })
      .then((products: Product[]) => {
        args.commit("setProducts", products);
      });
  }
};

const mutations = {
  setProducts(state: State, products: Product[]) {
    state.products = products;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
