import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import VueCookies from "vue-cookies";
import { PermissionLevel } from "./models/permissions/interface";

const PermissionLevelMap = {
  install: function(Vue, options) {
    Vue.prototype.$getPermissionLevel = key => {
      return PermissionLevel[key];
    };
  }
};
Vue.use(PermissionLevelMap);
Vue.use(VueCookies);
Vue.$cookies.config("7d");

Vue.config.productionTip = false;

// TODO: Axios may not be required so far
const instance = axios.create({
  baseURL: "http://localhost:5000/",
  timeout: 1000
});

Vue.prototype.axios = instance;

Vue.prototype.axios.interceptors.request.use(function(
  config: AxiosRequestConfig
) {
  config.headers.Authorization = "Bearer " + Vue.$cookies.get("token");
  return config;
});

const firebaseConfig = {
  apiKey: "AIzaSyDIUPPIzobZ9COICifmVGTE1mKargRIwV4",
  authDomain: "oposiciones-bombero.firebaseapp.com",
  databaseURL: "https://oposiciones-bombero.firebaseio.com",
  projectId: "oposiciones-bombero",
  storageBucket: "oposiciones-bombero.appspot.com",
  messagingSenderId: "757403862630",
  appId: "1:757403862630:web:840c2ae644fd4a487fcfa7",
  measurementId: "G-ZT9640DXDZ"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
// This watch is required. Otherwise firebase.auth().currentUser is always null
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    // User
  } else {
    // No user
  }
});

firebase.storage()

if (process.env.NODE_ENV !== "production") {
  // firebase.firestore().settings({
  //   host: "localhost:8081",
  //   ssl: false
  // });
  // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

Sentry.init({
  Vue: Vue,
  dsn:
    "https://10d0118ced1c45138e54dc735c657cd7@o496688.ingest.sentry.io/5571760",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  logErrors: true,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

Vue.prototype.axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response.status === 422 || error.response.status === 401) {
      store.commit("setToken", null);
      store.commit("setUser", null);
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);
