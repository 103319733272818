import * as firebase from "firebase/app";
import "firebase/firestore";

// TODO: Convertir tipos a interface y extraerlos a models
// TODO: renombrar a postcomment
type PostCommentType = {
  commentId: string;
  postId: string;
  authorId: string;
  authorDisplayName: string | null; // TODO: check if it can be not null only
  authorIsAdmin: boolean;
  body: string;
  created: Date;
  updated: Date;
  upvotes: Array<string>;
  revisions: Array<PostCommentType> | null; // TODO: check if it can be not null only
};

// TODO: renombrar a Post
type PostType = {
  postId: string;
  authorId: string;
  authorDisplayName: string;
  authorIsAdmin: boolean;
  editorId: string;
  editorDisplayName: string;
  editorIsAdmin: boolean;
  title: string;
  body: string;
  created: Date;
  updated: Date;
  tags: Array<string>;
  upvotes: Array<string>;
  numComments: number;
  comments: Array<PostCommentType>;
  revisions: Array<PostType> | null; // TODO: check if it can be not null only
  pinned: boolean;
  public: boolean;
};

type ForumState = {};

type ActionParam = {
  state: ForumState;
  commit: (mutation: string, value: any) => void;
  rootState: any;
  dispatch: any;
};

const state = {};

const getters = {};

const actions = {
  getAvailableTags(args: ActionParam): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("forum")
        .get()
        .then(doc => {
          if (doc.exists) {
            // TODO: cambiar en firestore que las tags son llaves no el string que se mostrara
            resolve(doc.data().available_tags);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // TODO: anadir return type que sea Post y utilizar maper en el resolve
  getPostById(
    args: ActionParam,
    params: { postId: string }
  ): Promise<Record<string, any>> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("forum")
        .doc(params.postId)
        .get()
        .then(doc => {
          if (doc.exists) {
            resolve({
              ...doc.data(),
              postId: params.postId
            });
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getAllPosts(args: ActionParam): Promise<Array<PostType>> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("forum")
        .get()
        .then(querySnapshot => {
          const retrievedPosts = [];
          for (let i = 0; i < querySnapshot.docs.length; ++i) {
            const doc = querySnapshot.docs[i];
            // TODO: mapear a posts
            retrievedPosts.push({
              ...doc.data(),
              postId: doc.id
            });
          }
          resolve(retrievedPosts);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getPaginatedPosts(
    args: ActionParam,
    params: {
      tags: Array<string>;
      page: number;
    }
  ): Promise<Array<PostType>> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  getPostsByTags(
    args: ActionParam,
    params: { tags: Array<string> }
  ): Promise<Array<PostType>> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  submitPost(args: ActionParam, params: { post: PostType }): Promise<PostType> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("forum")
        .add(params.post)
        .then(docRef => {
          params.post.postId = docRef.id;
          resolve(params.post);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  submitComment(
    args: ActionParam,
    params: { comment: PostCommentType }
  ): Promise<PostCommentType> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("forum/" + params.comment.postId + "/comments")
        .add(params.comment)
        .then(docRef => {
          params.comment.commentId = docRef.id;
          resolve(params.comment);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getAllCommentsFromPost(
    args: ActionParam,
    params: { postId: string }
  ): Promise<Array<PostCommentType>> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("forum/" + params.postId + "/comments")
        .get()
        .then(querySnapshot => {
          const retrievedComments = [];
          for (let i = 0; i < querySnapshot.docs.length; ++i) {
            const doc = querySnapshot.docs[i];
            retrievedComments.push({
              ...doc.data(),
              commentId: doc.id
            });
          }
          resolve(retrievedComments);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  editPost(args: ActionParam, params: { editedPost: PostType }): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  editComment(
    args: ActionParam,
    params: { editedComment: PostCommentType }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  upvotePost(args: ActionParam, params: { postId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  upvoteComment(
    args: ActionParam,
    params: { commentId: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  undoUpvotePost(args: ActionParam, params: { postId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  undoUpvoteComment(
    args: ActionParam,
    params: { commentId: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  deletePost(args: ActionParam, params: { postId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  },

  deleteComment(
    args: ActionParam,
    params: { commentId: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      reject();
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
