















































































import Vue from "vue";
import * as firebase from "firebase/app";
export default Vue.extend({
  name: 'Repaso',
  props: {
    deckId : {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      cards: [],
      i: 0,
      currentCard: {'front': '', 'back': '', 'detail': ''},
      show: false,
      history: [],
      finalizationDialog: false
    };
  },
  created() {
    this.getDeckCards().then(cards => {
      this.cards = cards;
      this.cards.sort(() => (Math.random() > .5) ? 1 : -1);
      if (this.cards.length == 0)
        this.finalizationDialog = true;
      else 
        this.currentCard = cards[this.i];
      this.loading = false;
    }).catch((e) => {
      console.log(e);
      this.error = true;
      this.loading = false;
    });
  },
  methods: {
    getDeckCards() {
      if (this.deckId != 'Todas') {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("users")
            .doc(this.$store.state.user.id)
            .collection("flashcards")
            .where('deck', '==', this.deckId)
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("users")
            .doc(this.$store.state.user.id)
            .collection("flashcards")
            .limit(100)
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    registerRecall(recall) {
      this.show = false;
      this.history.push({'id': this.currentCard.id, 'recall': recall})
      this.i += 1;
      if (this.i >= this.cards.length) {
        this.finalizationDialog = true;
      } else this.currentCard = this.cards[this.i];
    }
  }


})
