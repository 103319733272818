export default class Price {
  active: boolean;
  currency: string;
  description: string | null;
  interval: string;
  intervalCount: number;
  trialPeriodDays: string | null;
  type: string;
  unitAmount: number;
  id: string;

  constructor(
    id: string,
    active: boolean,
    currency: string,
    description: string,
    interval: string,
    intervalCount: number,
    trialPeriodDays: string,
    type: string,
    unitAmount: number
  ) {
    this.active = active;
    this.currency = currency;
    this.description = description;
    this.interval = interval;
    this.intervalCount = intervalCount;
    this.trialPeriodDays = trialPeriodDays;
    this.type = type;
    this.unitAmount = unitAmount;
    this.id = id;
  }

  toString(): string {
    return `${this.unitAmount / 100.0}€`;
  }
}
