















































































































import Vue from "vue";
import PostItem from "@/components/forum/PostItem.vue";
import { LogLevels } from "@/models/log/interface";
export default Vue.extend({
  name: "Forum",
  components: {
    PostItem
  },
  data() {
    return {
      loading: true,
      newPost: {
        title: "",
        body: "",
        tags: [],
        postingLoading: false,
        validForm: false,
        dialog: false
      },
      displayedPosts: [],
      availableTags: [],
      subforum:
        this.$router.currentRoute.name == "foro"
          ? null
          : this.$router.currentRoute.name == "cm_foro"
          ? "madrid"
          : "gencat"
    };
  },
  created() {
    this.getPosts();
    this.getTags();
  },
  methods: {
    getPosts() {
      this.$store
        .dispatch("forum/getAllPosts")
        .then(posts => {
          this.displayedPosts = posts;
          this.filterPosts(); //TODO: Hacer el filter en la query
          this.sortPosts();
        })
        .catch(error => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTags() {
      this.$store
        .dispatch("forum/getAvailableTags")
        .then(tags => {
          this.availableTags = tags;
        })
        .catch(error => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
        });
    },
    publishPost() {
      this.newPost.postingLoading = true;
      const newPost = {
        postId: null,
        authorId: this.$store.state.user.id,
        authorDisplayName: this.$store.state.user.username,
        authorIsAdmin: false, //TODO: Check in the store if user is admin
        editorId: null,
        editorDisplayName: null,
        editorIsAdmin: false, //TODO: Check in the store if user is admin
        title: this.newPost.title,
        body: this.newPost.body,
        created: new Date(),
        updated: null,
        tags: this.newPost.tags.map(v => this.availableTags[v]),
        upvotes: [],
        numComments: 0,
        comments: [],
        revisions: null,
        pinned: false,
        public: true,
        subforum: this.subforum
      };
      this.$store
        .dispatch("forum/submitPost", { post: newPost })
        .then(post => {
          this.displayedPosts.push(post);
          this.newPost.dialog = false;
        })
        .catch(error => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
        })
        .finally(() => {
          this.newPost.postingLoading = false;
          this.$refs.form.reset();
          this.newPost.tags = [];
        });
    },
    cancelPublication() {
      this.newPost.dialog = false;
      this.$refs.form.reset();
      this.newPost.tags = [];
    },
    sortPosts() {
      this.displayedPosts.sort((a, b) => (a.created > b.created ? 1 : -1));
    },
    filterPosts() {
      //console.log("Filtering posts");
      //console.log("Subforum:", this.subforum);
      const filtered = [];
      for (let i = 0; i < this.displayedPosts.length; ++i) {
        //console.log("i: ", i, "subforum: ", this.displayedPosts[i].subforum);
        if (this.displayedPosts[i].subforum == this.subforum) {
          filtered.push(this.displayedPosts[i]);
        }
      }
      this.displayedPosts = filtered;
    }
    // TODO: Paginacion
    // TODO: Pineado
    // TODO: Marcar cuentas de profes como ADMIN
    // TODO: Editar post y comentario
    // TODO: Borrar comentarios y posts
    // TODO: Responder a comentario concreto
    // TODO: Buscador de posts
    // TODO: Filtrar posts por etiqueta
  }
});
