<template>
  <v-container>
    <div
      class="pa-10 section-title"
      :style="
        $vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size: 38px;'
      "
    >
      Nuestros alumnos
    </div>
    <v-carousel hide-delimiter-background height="50vh">
      <v-carousel-item v-for="item in items" :key="item.title">
        <div style="display: flex; justify-content: center;">
          <v-img
            class="white--text align-end"
            :src="item.imgUrl"
            contain
            height="40vh"
            width="40vh"
          >
            <div style="display: flex; justify-content: center;">
              <div style="background: #00000099; width: 40.1vh; ">
                <v-card-title style="font-size: 2rem; padding: 15px;">
                  {{ item.name }}
                </v-card-title>
                <v-card-subtitle>
                  {{ item.title }}
                </v-card-subtitle>
              </div>
            </div>
          </v-img>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import * as firebase from "firebase/app";
export default {
  name: "AlumniTestimonials",
  data: () => ({
    items: [],
  }),
  created() {
    this.getTestimonials().then((items) => {
      this.items = items;
    });
  },
  methods: {
    getTestimonials() {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("resources")
          .doc("landing-content")
          .get()
          .then((doc) => {
            if (doc.exists) {
              resolve(doc.data()["alumni-testimonials"]);
            } else {
              reject("Document does not exist");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped></style>
