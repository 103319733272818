






























































































































































































// import ResultChart from "@/components/result/ResultChart.vue";
import Test from "@/views/Test.vue";
import UserSubmission from "@/models/user_submission/interface";
import { LogLevels } from "@/models/log/interface";
import * as firebase from "firebase/app";

export default {
  name: "Result",
  components: {
    //ResultChart,
    Test
  },
  props: {
    testId: {
      type: String,
      required: false,
      default: null
    },
    submissionId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      correct: 0,
      wrong: 0,
      submission: null as UserSubmission,
      showError: false,
      test: null,
      decks: [],
      newDeckDialog: false,
      nombreNuevoMazo: '',
    };
  },
  mounted() {
    this.loading = true;
    this.showError = false;
    this.$store
      .dispatch("tests/getUserSubmissionById", {
        userId: this.$store.state.user.id,
        testId: this.testId,
        submissionId: this.submissionId
      })
      .then(submission => {
        this.submission = submission;
        this.$store.dispatch("tests/getExam", this.testId).then(test => {
          this.test = test;
          this.nombreNuevoMazo = test.title;
          if (this.getUnixSeconds() < this.test.resultsDate.seconds) {
            this.showError = true;
          }
        });
      })
      .catch(error => {
        this.$store.dispatch("log", {
          level: LogLevels.ERROR,
          message: error
        });
        this.$store.commit("showMessage", {
          text: this.$t("views/result/error_messages/error_fetching_results"),
          color: "error"
        });
      })
      .finally(() => {
        this.loading = false;
      });

      this.getDecks().then(decks => this.decks = decks);
  },
  methods: {
    optionifyQuestions(questions) {
      const qs = [];
      questions.forEach(q => {
        const optionifiedQuestion = {... q}
        optionifiedQuestion.options = optionifiedQuestion.options || Object.fromEntries(Object.keys(q).filter(k => k.length == 1).map(k => [k, q[k]]))
        qs.push(optionifiedQuestion)       
      })
      return qs;
    },
    getUnixSeconds(): number {
      return Math.floor(Date.now() / 1000);
    },
    getDecks() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(this.$store.state.user.id)
        .get()
        .then(doc => {
          if (doc.exists) {
            const decks = doc.data().decks;
            resolve(decks);
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
    submitDeck() {
      if (this.decks && this.decks.includes(this.nombreNuevoMazo)) {
        this.$store.commit("showMessage", {
          text: 'Ya tienes un mazo que se llama '+this.nombreNuevoMazo,
          color: "error"
        });
      }
      else {
        if (this.decks) this.decks.push(this.nombreNuevoMazo);
        else this.decks = [this.nombreNuevoMazo]
        const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id);
        docRef.update({
          decks: this.decks
        })
        this.submitCards();
        this.$store.commit("showMessage", {
          text: "Tu mazo se ha creado correctamente",
          color: "success"
        });
      }
    },
    generateCards() {
      return this.optionifyQuestions(this.test.questions).map((q) => {
         return {
          front: q.text,
          back: q.options[q.correcta],
          detail: q.detalle ? q.detalle : null,
          created: new Date(),
          status: 'unseen',
          deck: this.nombreNuevoMazo,
          archived: false,
          author: 'Oposiciones Bombero',
          history: {}
         }
      });
    },
    submitCards() {
      const batch = firebase.firestore().batch();
      const cards = this.generateCards();
      cards.forEach(doc => {
        const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id).collection('flashcards').doc()
        batch.set(docRef, doc);
      })
      batch.commit();
    }
  }
};
