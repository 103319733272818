







































import Vue, { PropType } from "vue";
import { Answer } from "@/models/answer/interface";
import { ExamQuestion } from "@/views/Test.vue";

export default Vue.extend({
  name: "Question",
  props: {
    question: {
      type: Object as PropType<ExamQuestion>,
      required: true
    },
    last: Boolean,
    readonly: Boolean,
    number: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selected: this.question.answer as Answer
    };
  },
  watch: {
    selected() {
      this.$emit("option-selected", this.question.index, this.selected);
    }
  },
  methods: {
    onClick: function(answer: Answer) {
      if (!this.readonly) {
        if (this.selected === answer) {
          this.selected = null;
        } else {
          this.selected = answer;
        }
      }
    }
  }
});
