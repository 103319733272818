<template>
  <div class="class-link"
       @mouseover="mouseover"
       @mouseleave="mouseleave"
       @click="goToUnit"
  >
    <v-img
      :src="imgUrl"
      gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.5)"
      height="150px"
      style="position: relative;"
    >
      <v-chip
        v-if="t.display_category"
        :color="t.display_category.color"
        label
        small
        class="ma-2"
        style="float: right; cursor: pointer;"
      >
        {{ t.display_category.name }}
      </v-chip>
      <transition
        v-if="t.lessons.length + t.documents.length + t.tests.length + t.links.length > 0 || false "
        name="fade"
      >
        <div
          v-if="showInfo" 
          style="
            color: white;
            position: absolute;
            background: black;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 3;
            padding: 7px;
          "
        >
          <span v-if="t.lessons && t.lessons.length > 0">
            <small>
              <v-icon dark>mdi-video</v-icon> {{ t.lessons.length }} 
            </small>
          </span>
          <span v-if="t.documents && t.documents.length > 0">
            <small>
              <v-icon dark>mdi-book-multiple-outline</v-icon> {{ t.documents.length }}
            </small>
          </span>
          <span v-if="t.tests && t.tests.length > 0">
            <small>
              <v-icon dark>mdi-notebook-check-outline</v-icon> {{ t.tests.length }}
            </small>
          </span>
          <span v-if="t.links && t.links.length > 0">
            <small>
              <v-icon dark>mdi-link</v-icon> {{ t.links.length }}
            </small>
          </span>
        </div>
      </transition>
    </v-img>
    <div class="tu-info">
      {{ t.title }}
      <div class="tu-details">
        <div style="margin-bottom: 4px;">
          <small>
            {{ t.professor ? t.professor : "Oposiciones Bombero" }}
          </small>
        </div>
        <div>
          <small>
            {{
              formatDate(t.updated)      
            }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { PermissionLevel } from "@/models/permissions/interface";
import * as firebase from "firebase/app";
export default Vue.extend({
  name: "TeachingUnitCard",
  props: {
    t: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      present: new Date(),
      showInfo: false,
      imgUrl: null
    }
  },
  created() {
    this.scrFromStorage()
  },
  methods: {
    scrFromStorage() {
      const storageRef = firebase.storage().ref();
      const imgRef = storageRef.child(`web-assets/tu-cards/${this.t.id}.jpg`)
      
      imgRef.getDownloadURL()
        .then(url => this.imgUrl = url)
        .catch(error => {
          this.imgUrl = this.imgSrc()
        })
      
      
    },
    imgSrc() {
      return require('@/assets/stock'+this.t.title.length%10+'.jpeg');
    },
    mouseover(){
      this.showInfo = true;
    },
    mouseleave(){
      this.showInfo = false;
    },
    formatDate(date) {
      if ('toDate' in date) {
        date = date.toDate()
      }
      const a = date;
      const b = new Date();
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      //console.log('Date: ', date);
      let result = 'Hace ';
      if (diffDays == 0) result = 'Hoy';
      else if (diffDays == 1)  result += ' 1 día';
      else if (1 < diffDays && diffDays < 7)   result += ' ' + diffDays + ' días';
      //console.log('Days: ', diffDays);
      
      const diffWeeks = Math.floor(diffDays / 7);
      if (diffWeeks == 1) result += ' 1 semana';
      else if (1 < diffWeeks && diffWeeks < 4) result += ' ' + diffWeeks + ' semanas';
      //console.log('Weeks: ', diffWeeks);

      const diffMonths = Math.floor(diffWeeks / 4);
      if (diffMonths == 1) result += ' 1 mes';
      else if (1 < diffMonths && diffMonths < 12) result += ' ' + diffMonths + ' meses';
      //console.log('Months: ', diffMonths);

      const diffYears = Math.floor(diffMonths / 12);
      if (diffYears == 1) result += ' 1 año';
      else if (1 < diffYears) result += ' ' + diffYears + ' años';
      //console.log('Years: ', diffYears);
      //console.log('Result: ', result);

      if (diffDays < 0) {
        result = 'Disponible ';
        if (diffDays == -1) result += 'mañana';
        else if (diffDays <= 7) result += 'en ' + (-diffDays) + ' días';
        else result += 'próximamente';
      }
      return result;
    },
    goToUnit() {
      if (!this.userCanAccesUnit()) {
        this.$emit("subscription-required");
      }
      else {
        this.$router.push({ name: 'unit', params: { teachingUnitId: this.t.id } })
      }
    },
    userCanAccesUnit() {
      return this.$store.state.user.subscribedSince[
        PermissionLevel.PREMIUM
      ];
    }
  }
});
</script>

<style>
.class-link {
  color: white;
  margin: 10px;
  border-radius: 10px;
  height: 300px;
  width: 254px;
  cursor: pointer;
}

.class-link:hover {
  opacity: 0.8;
}

.tu-info {
  font-size: 17px;
  margin: 10px 10px 10px 0px;
  padding-bottom: 10px;
  font-weight: 500;
  white-space: normal;
  overflow-wrap: break-word;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.tu-details {
  font-weight: 400;
  color: #aaa;
} 

 .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>