


















































































import Vue from "vue";
import * as firebase from "firebase/app";
import TeachingGroupCard from "@/components/TeachingGroupCard.vue";
import router from "@/router";
export default Vue.extend({
  name: "Flashcards",
  components: {
    //TeachingUnitCard
    //  TeachingGroupCard,
  },
  data() {
    return {
      loading: true,
      teachingUnits: [],
      
      // DIALOG
      dialog: false,
      title: null,
      publishingDate: null,
      hidden: null,
      freeToRegistered: null,
      lessons: [],
      documents: [],
      links: [],
      tests: [],

      decks: [],
      nombreNuevoMazo: "",
      newDeckDialog: false

    };
  },
  created() {
    // this.getAdmin();
    this.getDecks().then(decks => {
      decks.unshift('+', 'Todas');
      this.decks = decks;
      this.loading = false;
    }).catch((e) => {
      this.decks = ['+', 'Todas'];
      this.loading = false;
    });
  },
  methods: {
    goToDeck(e, deck) {
      this.$router.push({ name: 'deck', params: { deckId: deck } })
    },
    openNewDeckDialog() {
      this.newDeckDialog = true;
    },
    submitDeck() {
      if (this.decks.includes(this.nombreNuevoMazo)) {
        alert('Ya tienes un mazo que se llama '+this.nombreNuevoMazo );
      }
      else {
        this.decks.push(this.nombreNuevoMazo);
        const docRef = firebase.firestore().collection('users').doc(this.$store.state.user.id);
        docRef.update({
          decks: this.decks.slice(2)
        })
      }
      this.nombreNuevoMazo = "";
    },
    sortCategories: function(cats) {
      // Create items array
      const items = Object.keys(cats).map(function(key) {
        return [key, cats[key]];
      });


      items.sort(function(first, second) {
        if(first[0] < second[0]) { return -1; }
        if(first[1].name > second[1].name) { return 1; }
        return 0;
      });

      return items.reduce(function(obj, entry) {
        obj[entry[0]] = entry[1];
        return obj;
      }, {});
    },

    getDecks() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(this.$store.state.user.id)
        .get()
        .then(doc => {
          if (doc.exists) {
            const decks = doc.data().decks;
            resolve(decks);
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  }
});
