import Vue from "vue";
import Vuex from "vuex";

import * as getters from "@/store/getters";
import * as actions from "@/store/actions";
import * as mutations from "@/store/mutations";

import user from "@/store/modules/user";
import tests from "@/store/modules/tests";
import forum from "@/store/modules/forum";
import payment from "@/store/modules/payment";
import vm from "@/store/modules/vm";

Vue.use(Vuex);

interface Message {
  text: string;
  color: string;
}

export default new Vuex.Store({
  state: {
    messages: [] as Array<Message>
  },
  getters,
  actions,
  mutations,
  modules: {
    user: user,
    tests: tests,
    forum: forum,
    payment: payment,
    vm: vm
  }
});
