<template>
  <div class="sub">
    <!-- <div class="pa-4 section-title" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:24px;' : 'font-size: 34px;'">
      Estás entrando en la zona para alumnos
    </div> -->
    <precio-landing isDialog isAnual/>
  </div>
</template>

<script>
import Vue from "vue";
import PrecioLanding from "../components/landing/PrecioLanding.vue";
export default Vue.extend({
  name: 'SubscriptionNeeded',
  components: { PrecioLanding },
  data() {
    return {};
  },
  methods: {}
});
</script>

<style>

.sub {
  text-align: center;
  color: white;
  font-weight: 350;
}

</style>