<template>
  <div class="tests">
    <div v-if="!loading" class="centered-content">
      <div class="text-h5 text-sm-h3 page-title">
        {{ title }}
      </div>
      <v-switch
        v-if="userIsAdmin"
        v-model="editMode"
        dark
        label="Modo edición"
      />

      <router-link
        v-for="t in availableTests"
        :key="t.id"
        :to="editMode ? {name: 'test_input', params: {testIdToEdit: t.id}} :{ name: 'test', params: { testId: t.id, submission: null } }"
        style="text-decoration: none; color:white;"
      >
        <div class="test-link">
          <v-icon left dark>
            mdi-check
          </v-icon>
          {{ t.title }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";
import Vue from "vue";
import * as firebase from "firebase/app";
import { LogLevels } from "@/models/log/interface";

export default Vue.extend({
  name: "Tests",
  components: {},
  props: {
    category: {
      type: String,
      default: "Tests",
      required: true
    },
    title: {
      type: String,
      default: "Tests",
      required: false
    }
  },
  data() {
    return {
      userIsAdmin: false,
      editMode: false,
      loading: true,
      availableTests: []
    };
  },
  created() {
    this.getAdmin();
    this.$store
      .dispatch("tests/getTestsByCategory", { category: this.category })
      .then(tests => {
        this.availableTests = tests;
        this.sortTests();
      })
      .catch(error => {
        this.$store.dispatch("log", {
          level: LogLevels.ERROR,
          message: error
        });
        this.$store.commit("showMessage", {
          text: this.$t(
            "views/tests/error_messages/error_fetching_tests_by_category"
          ),
          color: "error"
        });
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    sortTests() {
      // console.log(this.availableTests);
      this.availableTests.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    getAdmin() {
      firebase
        .firestore()
        .collection("resources")
        .doc("admin-users")
        .get()
        .then((doc) => {
          const found = doc.data().list.includes(this.$store.state.user.id);
          this.userIsAdmin = found;
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }
});
</script>

<style scoped>
.centered-content {
  color: white;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px;
  max-width: 1000px;
  align-items: center;
}

.page-title {
  margin-bottom: 40px;
}

.test-link {
  background: #0a0d1a;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 10px;
}

.test-link:hover {
  background: #11162c;
  margin-bottom: 10px;
  padding: 16px;
}
</style>
