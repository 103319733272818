import Price from "@/models/price/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";

export default function firebaseToPrice(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >
): Price {
  const data = document.data();
  return new Price(
    document.id,
    data.active,
    data.currency,
    data.description,
    data.interval,
    data.interval_count,
    data.trial_period_days,
    data.type,
    data.unit_amount
  );
}
