<template>
  <div class="faq-landing mb-4" >
    <div class="pa-10 section-title" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size: 38px;'">
      Preguntas frecuentes
    </div>
    <v-expansion-panels
      focusable
      class="d-flex flex-column justify-center align-center"
      v-for="item in faqs"
      :key="item.question"
      dark
    >
      <v-expansion-panel class="faq-panel-general ">
        <v-expansion-panel-header class="faq-panel-header" >
          {{ item.question  }}
          </v-expansion-panel-header>
        <v-expansion-panel-content class="faq-panel-content">
          {{ item.answer }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import * as firebase from "firebase/app";
export default {
  name: "faq-landing",
  data() {
    return {
      faqs: [],
    };
  },
  created() {
    this.getFAQs().then(
      faqs => {
        this.faqs = faqs;
      }
    )
  },
  methods: {
    getFAQs() {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("resources")
          .doc("landing-content")
          .get()
          .then(doc => {
            if (doc.exists) {
              resolve(doc.data().faqs);
            } else {
              reject("Document does not exist");
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }, 
  }
};
</script>

<style scoped>
.faq-landing {
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq-panel-general {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq-panel-header {
  background: #202741;
  color: white;
  font-weight: 500;
}

.faq-panel-content {
  background: #202741;
  color: white;
  padding: 1rem;
  padding-top: 2rem;
  font-weight: 400;
}

</style>
