import { render, staticRenderFns } from "./TeachingGroupCard.vue?vue&type=template&id=5bfe7bf4&"
import script from "./TeachingGroupCard.vue?vue&type=script&lang=js&"
export * from "./TeachingGroupCard.vue?vue&type=script&lang=js&"
import style0 from "./TeachingGroupCard.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
