export const showMessage = async (
  state: any,
  params: { text: string; color: string }
) => {
  state.messages.push({
    text: params.text,
    color: params.color
  });
};

export const consumeMessage = (
  state: any,
  params: { text: string; color: string }
) => {
  if (state.messages.length > 0) {
    state.messages.shift();
  }
};
