<template>
  <div class="hero-image" style="color: white; padding-bottom: 50px;">
    <v-dialog v-model="newSub" width="60%" persistent>
      <v-card>
        <v-card-title :class="{ headline: !$vuetify.breakpoint.smAndDown }">
          ¡Gracias por suscribirte!
        </v-card-title>
        <v-card-text style="font-size: 16px">
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              newSub = false;
            "
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="pa-10 section-title" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size: 38px;'">
      ¿Quieres estar al día de <strong>todo</strong> lo relacionado con la oposición?
    </div>
    <div class="news-items" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:16px;' : 'font-size: 22px;'">
      Suscríbete para recibir nuestro boletín <br>
      Es gratis y puedes irte cuando quieras<br>
      Recibirás convocatorias y noticias de interés<br>
      
    </div>
    <v-form
      ref="form"
      :style="$vuetify.breakpoint.smAndDown ? 'width: 80%; margin: 0 auto;' : 'width: 50%; margin: 0 auto;'"
    >
      <v-row v-if="!$vuetify.breakpoint.smAndDown">
        <v-col cols="4">
          <v-text-field
            v-model="name"
            style="border-radius: 0px;"
            solo
            label="Tu nombre"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="email"
            style="border-radius: 0px;"
            solo
            type="email"
            label="Tu mejor email"
            :rules="emailRules"
            required
          />
        </v-col>
      </v-row>
      <div v-else>
        <v-text-field
          v-model="name"
          style="border-radius: 0px; margin-bottom: -25px;"
          solo
          label="Tu nombre"
        />
        <v-text-field
          v-model="email"
          style="border-radius: 0px;"
          solo
          label="Tu mejor email"
          :rules="emailRules"
          required
        />
      </div>
      <v-btn
        large
        color="accent"
        style="border-radius: 0px;"
        block
        class="mr-4"
        @click="validate"
      >
        Suscribirme
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import * as firebase from "firebase/app";
export default Vue.extend({
  name: 'NewsletterSubscribe',
  data() {
    return {
      newSub: false,
      email: '',
      name: '',
      emailRules: [
        v => !!v || 'No parece un email válido',
        v => /.+@.+\..+/.test(v) || 'No parece un email válido',
      ]
    }
  },
  methods: {
    validate () {
        if(this.$refs.form.validate())
          this.registerSub();
      },
    registerSub() {
      firebase
      .firestore()
      .collection('newsletter-subs')
      .doc(this.email)
      .set({name: this.name})
      .then(() => {this.newSub = true; this.email = ''; this.name = ''; this.$refs.form.reset()})
    }
  }
});
</script>

<style>

.hero-image {
  background-image: url(../../assets/banner-2-blue.jpg);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.news-items {
  text-align: center; 
  color: white; 
  font-weight: 350;
  margin-bottom: 45px;
  margin-top: -15px;
}

</style>