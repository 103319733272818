import * as firebase from "firebase/app";
import "firebase/firestore";
import UserSubmission from "@/models/user_submission/interface";
import firebaseToUserSubmission from "@/models/user_submission/mapper";
import testsMappers from "@/models/test/mapper";
import firebaseToTeachingUnit from "@/models/teaching_unit/mapper";
import firebaseToTeachingGroup from "@/models/teaching_group/mapper";
import Test from "@/models/test/interface";
import { LogLevels } from "@/models/log/interface";
import TeachingUnit from "@/models/teaching_unit/interface";
import TeachingGroup from "@/models/teaching_group/interface";

// enum Actions {
//   GET_USER_SUBMISSION = "GET_USER_SUBMISSION",
//   GET_EXAM = "GET_EXAM",
//   FINISH_SUBMISSION = "FINISH_SUBMISSION",
//   START_SUBMISSION = "START_SUBMISSION"
// }

interface State {
  foo: number;
}
const state: State = {
  foo: 0
};

type ActionParam = {
  state: State;
  commit: (mutation: string, value: any) => void;
  rootState: any;
  dispatch: any;
};

const getters = {};

const actions = {
  getUserSubmission(
    args: ActionParam,
    params: { testId: string; userId: string }
  ): Promise<UserSubmission> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users/" + params.userId + "/submissions")
        .where("testId", "==", params.testId)
        .get()
        .then(results => {
          if (results.empty) {
            resolve(null);
          } else {
            const document = results.docs[0];
            const submission = firebaseToUserSubmission(document);
            submission.testId = params.testId;
            resolve(submission);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUserSubmissionById(
    args: ActionParam,
    params: { userId: string; testId: string; submissionId: string }
  ): Promise<UserSubmission> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users/" + params.userId + "/submissions")
        .doc(params.submissionId)
        .get()
        .then(document => {
          const submission = firebaseToUserSubmission(document);
          submission.testId = params.testId;
          resolve(submission);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getExam(args: ActionParam, testId: string) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("tests")
        .doc(testId)
        .get()
        .then(doc => {
          if (doc.exists) {
            resolve(testsMappers.firebaseToTest(doc));
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getTestCategories(args: ActionParam): Promise<Record<string, any>> {
    // test_categories.availableCategories is an array of the form
    // [ {name: "Comunidad de Madrid", code: "MADRID"},
    //   {name: "AENA", code: "AENA"} ]
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("tests_categories")
        .get()
        .then(doc => {
          if (doc.exists) {
            //TODO (Optional): Mapper for this one
            const categories = doc.data().availableCategories;
            resolve(categories);
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getActiveSimulationTestId(args: ActionParam): Promise<string> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("simulation")
        .get()
        .then(doc => {
          if (doc.exists) {
            const activeSimulationTestId = doc.data().activeSimulationTestId;
            if (activeSimulationTestId) resolve(activeSimulationTestId);
            else reject("No hay ningún simulacro definido");
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getTeachingUnit(
    args: ActionParam,
    params: { teachingUnitId: string }
  ) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("teaching-units")
        .doc(params.teachingUnitId)
        .get()
        .then(doc => {
          if (!doc.exists) {
            reject();
          } else {
            resolve({
              id: doc.id,
              ...doc.data()
            });
          }
        });
    });
  },

  getTestsIndex(args: ActionParam) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("tests_index")
        .get()
        .then(doc => {
          if (doc.exists) {
            const index = testsMappers.firebaseToTestsIndex(doc);
            resolve(index);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //TODO: Add return type and create interface to map results instead of returning a consrtructed object
  getTestsByCategory(args: ActionParam, params: { category: string }) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("tests")
        .where("category", "==", params.category)
        .get()
        .then(function(querySnapshot) {
          const result = [];
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            result.push({
              id: doc.id,
              ...doc.data()
            });
          });
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTeachingGroup(
    args: ActionParam,
    params: { teachingGroupId: string }
  ): Promise<TeachingGroup> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("teaching-units")
        .where("category", "==", params.teachingGroupId)
        .get()
        .then(function(querySnapshot) {
          resolve(
            firebaseToTeachingGroup(querySnapshot, params.teachingGroupId)
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  finishSubmission(
    args: ActionParam,
    submission: UserSubmission
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users/" + submission.userId + "/submissions")
        .doc(submission.submissionId)
        .update({
          grade: submission.grade,
          answers: submission.answers,
          ended: submission.ended
        })
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  startSubmission(args: ActionParam, submission: UserSubmission): Promise<any> {
    //UserSubmission
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users/" + submission.userId + "/submissions")
        .add(submission)
        .then(function(docRef) {
          // TODO: Guarrada enorme
          resolve({
            submissionId: docRef.id,
            ended: null,
            started: {
              seconds: Math.floor(Date.now() / 1000)
            }
          });
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state: state as State,
  getters,
  actions,
  mutations
};
