import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import firebaseToPrice from "@/models/price/mapper";
import firebaseToProduct from "@/models/product/mapper";
import Product from "@/models/product/interface";
import Price from "@/models/price/interface";
import { loadStripe } from "@stripe/stripe-js";
import { LogLevels } from "@/models/log/interface";

interface State {
  foo: string;
}

// TODO: Extract stripe key to build environment
const state = () => ({
  foo: ""
});

type ActionParam = {
  state: State;
  commit: (mutation: string, value: any) => void;
  rootState: any;
  dispatch: any;
};

const getters = {};

const actions = {
  getProducts(args: ActionParam): Promise<Product[]> {
    return firebase
      .firestore()
      .collection("products")
      .where("active", "==", true)
      .where("name", "==", "Suscripción Academia")
      .get()
      .then(querySnapshot => {
        const promises = [];
        querySnapshot.forEach(doc => {
          promises.push(
            doc.ref
              .collection("prices")
              .get()
              .then(pricesColl => {
                const prices: Array<Price> = [];
                pricesColl.forEach(priceSnap => {
                  prices.push(firebaseToPrice(priceSnap));
                });
                return firebaseToProduct(doc, prices);
              })
          );
        });
        return Promise.all(promises);
      });
  },

  async checkoutProduct(args: ActionParam, priceId: string): Promise<void> {
    const docRef = await firebase
      .firestore()
      .collection("users")
      .doc(args.rootState.user.id)
      .collection("checkout_sessions")
      .add({
        price: priceId,
        //tax_rates: ["txr_1I1VYvBWV5dMUOPeFwp19cuU"], //TODO: mirar esto con mas detalle
        allow_promotion_codes: true,
        success_url: window.location.href,
        cancel_url: window.location.href
      });

    docRef.onSnapshot(async snap => {
      const { error, sessionId } = snap.data();
      if (error) {
        args.dispatch(
          "log",
          { level: LogLevels.ERROR, message: error },
          { root: true }
        );
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        const stripe = await loadStripe(
          "pk_live_H5qd3W6MFbJ4cL1VFPiCvsoa00POPkXCCl",
          { locale: "es" }
        );
        stripe.redirectToCheckout({ sessionId });
      }
    });
    return;
  },

  async manageProduct(args: ActionParam): Promise<void> {
    const functionRef = firebase
      .functions()
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
    const { data } = await functionRef({ returnUrl: window.location.href });
    window.location.assign(data.url);
    return;
  }
};

const mutations = {};

export default {
  namespaced: true,
  state: state,
  getters,
  actions,
  mutations
};
