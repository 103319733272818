<template>
  <div class="group-link"
       @mouseover="mouseover"
       @mouseleave="mouseleave"
       @click="goToGroup"
  >
    <v-img
      dark
      class="group-image"
      :src="imgUrl"
      gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
      height="250px"
      :style="'border-bottom-color: '+tg.color+';'"
    >
      <span class="myspan"
      :style="'background: '+tg.color+';'"
      >{{ tg.name }}</span>
    </v-img>
  </div>
</template>
<script>
import Vue from "vue";
import * as firebase from "firebase/app";
// import { PermissionLevel } from "@/models/permissions/interface";
export default Vue.extend({
  name: "TeachingGroupCard",
  props: {
    tg : {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showInfo: false,
      imgUrl: null
    }
  },
  created() {
    this.scrFromStorage()
  },
  methods: {
    scrFromStorage() {
      const storageRef = firebase.storage().ref();
      const imgRef = storageRef.child(`web-assets/tg-cards/${this.tg.id}.jpg`)
      imgRef.getDownloadURL().then(url => this.imgUrl = url)
      .catch(error => {
        console.log(error)
        this.imgUrl = this.imgSrc()
      })
    },
    imgSrc() {
      return require('@/assets/stock'+(this.tg.name.length + this.tg.id.length)%10+'.jpeg');
    },
    mouseover(){
      this.showInfo = true;
    },
    mouseleave(){
      this.showInfo = false;
    },
    
    goToGroup() {
        this.$router.push({ name: 'group', params: { teachingGroupId: this.tg.id } })
    },
  }
});
</script>

<style>

.group-image {
  align-items: center;
  position: relative; 
  color: white; 
  font-size: 30px; 
  font-family: 'Oswald', sans-serif;
  border-bottom: 6px solid;
  border-bottom-color: crimson;
  border-radius: 0.5em;
  text-transform: uppercase;
}

.group-link {
  color: white;
  width: 333px;
  max-width: 90vw;
  cursor: pointer;
}

.group-image:hover {
  filter: brightness(1.05);
}

.myspan {
    padding: 7px;
    background-color: rgba(26, 26, 26, 0.62);
    z-index: 2;
}

 .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>