var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post_list_item"},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.subforum == 'madrid')?_c('router-link',{attrs:{"to":{
              name: 'cm_post',
              params: { postId: _vm.postId }
            }}},[_c('div',{staticClass:"reduced_title"},[_vm._v(_vm._s(_vm.title))])]):(_vm.subforum == 'gencat')?_c('router-link',{attrs:{"to":{
              name: 'gencat_post',
              params: { postId: _vm.postId }
            }}},[_c('div',{staticClass:"reduced_title"},[_vm._v(_vm._s(_vm.title))])]):_c('router-link',{attrs:{"to":{
              name: 'post',
              params: { postId: _vm.postId }
            }}},[_c('div',{staticClass:"reduced_title"},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"md":"auto"}},_vm._l((_vm.tags),function(tag){return _c('v-chip',{key:tag,staticClass:"ma-2",attrs:{"label":"","small":"","color":"accent"}},[_vm._v(" "+_vm._s(tag)+" ")])}),1),_c('v-col',[_c('div',{staticClass:"publicado_en",staticStyle:{"float":"right"}},[_c('small',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.created))+" "),_c('strong',[_vm._v(_vm._s(_vm.authorDisplayName))])])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }