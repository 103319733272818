import User from "@/models/user/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";
import Address from "@/models/address/interface";
import UserMetrics from "@/models/user_metrics/interface";
import { firebaseDataToUserMetrics } from "@/models/user_metrics/mapper";
import { firebaseDataToAddress } from "@/models/address/mapper";

export default function firebaseToUser(
  document: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >
): User {
  const data = document.data();
  return {
    achievements: [],
    address: firebaseDataToAddress(data.address),
    birthDate: data.birthDate?.toDate(),
    expirationBanned: null,
    expirationPremium: null,
    metrics: firebaseDataToUserMetrics(data.metrics),
    drivingLicenses: data.drivingLicenses,
    pastOpos: data.pastOpos,
    nextOpos: data.nextOpos,
    otherAcademies: data.otherAcademies
  };
}
