






























































































































































































import Vue from "vue";
import Login from "@/components/Login.vue";
import update from "@/mixins/update";
import CookieLaw from "vue-cookie-law";
import * as firebase from "firebase/app";

export default Vue.extend({
  name: "App",
  components: {
    Login,
    CookieLaw
  },
  mixins: [update],
  data: () => ({
    showLogin: false as boolean,
    queue: [],
    tab: null,
    loading: true,
    // drawer  
    drawer: false,
    group: null,
      items: [
        { title: 'Academia', icon: 'mdi-book', to: { name:  'academia'  } },
        { title: 'Directos', icon: 'mdi-video', to: { name: 'unit', params: { teachingUnitId: 'directos' } }  },
        { title: 'Tests', icon: 'mdi-notebook-check-outline', to: { name: 'test_categories' } },
        // { title: 'Preparación física', icon: 'mdi-weight-lifter', to: {name: 'group', params: { teachingGroupId: 'preparacion_fisica' } } },
        { title: 'Flashcards', icon: 'mdi-card-bulleted-outline', to: { name: 'flashcards' } },
        { title: 'Simulacro', icon: 'mdi-book-clock', to: { name: 'landing_simulacro' } },
      ],
      mini: true,
  }),
  computed: {
    loginWidth: function() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      } else {
        return "50%";
      }
    },
    userPic: function() {
        const user = firebase.auth().currentUser;
        return user.photoURL;
    }
  },
  watch: {
    $route() {
      this.showLogin = false;
    },
    "$store.state.messages"() {
      this.checkMessages();
    }
  },
  mounted() {
    this.$store.dispatch("user/loadFromCookies");
    this.loading = false; // TODO: Check lifecycle
  },
  methods: {
    logout: function() {
      this.$store.dispatch("user/logout");
      this.$router.push({ name: "home" });
    },
    login: function() {
      this.$router.push({ name: "login" });
    },
    checkMessages: function() {
      this.$store.dispatch("consumeMessage").then(message => {
        if (message) {
          this.queue.push({
            show: true,
            text: message.text,
            color: message.color,
            date: new Date().getTime()
          });
          this.checkMessages();
        }
      });
    },
    goToProfile: function() {
      this.$router.push({ name: "profile" });
    }
  }
});
