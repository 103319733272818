import Test from "@/models/test/interface";
import * as firebase from "firebase/app";
import "firebase/firestore";

const funcs = {
  firebaseToTest: function(
    document: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >
  ) {
    const data = document.data();
    return {
      testId: document.id,
      ...data
    };
  },
  firebaseToTestsIndex: function(
    document: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >
  ) {
    const allTests = document.data();
    const tests = [];
    for (const testId in allTests) {
      tests.push({ id: testId, ...allTests[testId] });
    }
    return tests;
  }
};

export default funcs;
