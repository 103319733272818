




























































































import Vue from "vue";
// import TeachingUnit from "@/models/teaching_unit/interface";
// import { PermissionLevel } from "@/models/permissions/interface";
import * as firebase from "firebase/app";
import TeachingGroupCard from "@/components/TeachingGroupCard.vue";
// import TeachingUnitCard from "@/components/TeachingUnitCard.vue";
export default Vue.extend({
  name: "Academia",
  components: {
    //TeachingUnitCard
     TeachingGroupCard,
  },
  data() {
    return {
      loading: true,
      teachingUnits: [],
      
      // DIALOG
      dialog: false,
      title: null,
      publishingDate: null,
      hidden: null,
      freeToRegistered: null,
      lessons: [],
      documents: [],
      links: [],
      tests: [],

      //Admin mode
      adminMode: false,

      categoriesMap: null,
    };
  },
  created() {
    // this.getAdmin();
    this.getCategories().then(categories => {
      this.categoriesMap = categories;
      this.loading = false;
    });
  },
  methods: {
    sortCategories: function(cats) {
      // Create items array
      const items = Object.keys(cats).map(function(key) {
        return [key, cats[key]];
      });

      // console.log(items);

      items.sort(function(first, second) {
        if(first[0] < second[0]) { return -1; }
        if(first[1].name > second[1].name) { return 1; }
        return 0;
      });
// console.info(items);

      return items.reduce(function(obj, entry) {
        obj[entry[0]] = entry[1];
        return obj;
      }, {});
    },
    // filteredUnits: function(cat) {
    //   if (cat == 'last') return this.teachingUnits.filter(unit => !unit.hidden).slice(0,4);
    //   return this.teachingUnits.filter(unit => unit.category == cat && !unit.hidden);
    // },
    // injectDisplayCategory(units) {
    //   return units.map(
    //     u => Object.assign(u, { display_category: this.categoriesMap[u.category]})
    //   )
    // },
    getCategories() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("academia_categories")
        .get()
        .then(doc => {
          if (doc.exists) {
            const categories = doc.data().categories;
            resolve(categories);
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
    // getUnits() {
    //     return new Promise((resolve, reject) => {
    //       firebase
    //         .firestore()
    //         .collection("teaching-units")
    //         .get()
    //         .then(function(querySnapshot) {
    //           const result = [];
    //           querySnapshot.forEach(function(doc) {
    //             result.push({
    //               id: doc.id,
    //               ...doc.data()
    //             });
    //           });
    //           resolve(result);
    //         })
    //         .catch(error => {
    //           reject(error);
    //         });
    //     });
    // },
    // sortUnits() {
    //   this.teachingUnits.sort((a: TeachingUnit, b: TeachingUnit) =>
    //     a.publishingDate > b.publishingDate ? -1 : 1
    //   );
    // },
    // filterUnits() {
    //   //Show to the user only those units they can access in the present and future
    //   const subStartDate = this.$store.state.user.subscribedSince[
    //     PermissionLevel.PREMIUM
    //   ];
    //   if (!subStartDate) {
    //     this.teachingUnits = this.teachingUnits.filter(
    //       (tu: TeachingUnit) => tu.freeToRegistered && !tu.hidden
    //     );
    //   } else {
    //     this.teachingUnits = this.teachingUnits.filter(
    //       (tu: TeachingUnit) => !tu.hidden
    //     );
    //   }
    // },
    // getAdmin() {
    //   firebase.firestore().collection('resources').doc('admin-users')
    //       .get()
    //       .then((doc) => {
    //           const found = doc.data().list.includes(this.$store.state.user.id);
    //           this.userIsAdmin = found;
    //       })
    //       .catch((error) => {
    //         console.log("Error getting documents: ", error);
    //       });
    // },
    // async addUnit() {
    //   try {
    //     if (this.title && this.publishingDate) {
    //       await firebase.firestore().collection('teaching-units').add({
    //         title: this.title,
    //         publishingDate: new Date(this.publishingDate),
    //         expirationDate: this.addDays(new Date(this.publishingDate), 7),
    //         category: this.teachingGroupId,
    //         hidden: this.hidden,
    //         freeToRegistered: this.freeToRegistered,
    //         lessons: this.lessons,
    //         documents: this.documents,
    //         links: this.links,
    //         tests: this.tests
    //       })

    //       this.getUnits();
    //       this.title = null;
    //       this.publishingDate = null;
    //       this.hidden = null;
    //       this.freeToRegistered = null;
    //       this.lessons = [];
    //       this.documents = [];
    //       this.links = [];
    //       this.tests = [];
    //     } else {
    //       this.$store.commit("showMessage", {
    //             text: "Completa los campos obligatorios",
    //             color: "error"
    //       });
    //       this.dialog = true;
    //     } 
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // addDays(date, days) {
    //   const result = new Date(date);
    //   result.setDate(result.getDate() + days);
    //   return result;
    // },
    // formatDate(date) {
    //   //return date;
    //   return date.toDate().toLocaleDateString();
    // }
  }
});
