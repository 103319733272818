










import Login from "@/components/Login.vue";

export default {
  name: "LoginView",
  components: { Login },
  data() {
    return {};
  },
  mounted() {
    if (this.$cookies.get("token") !== null) {
      this.$nextTick(() => {
        this.$router.push(this.$route.query.redirect || { name: "home" });
      });
    }
  },
  methods: {
    onLogin: function() {
      this.$router.push(this.$route.query.redirect || { name: "home" });
    }
  }
};
