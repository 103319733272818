<template>
  <div v-if="userIsAdmin" class="page">
    <v-btn class="mx-2" dark color="blue" @click="testIdToEdit ? updateTest() : submitTest()">
      CONFIRMAR
    </v-btn>
    <v-text-field
      v-model="title"
      placeholder="Título del test"
      hint="Título del test"
      autocomplete="off"
      solo
      style="width: 600px; font-size:22px;"
    />
    <v-select
      v-if="!loading"
      v-model="category"
      :items="availableCategories"
      label="Categoría"
    />
    <v-text-field
      v-model="numOptions"
      type="number"
      autocomplete="off"
      label="Número de respuestas"
    />
    <v-checkbox v-model="shuffleAtEnd" label="Desordenar" />
    <v-checkbox v-model="isTimed" label="Simulacro" />
    <div v-if="isTimed" style="width:300px;">
      <v-radio-group v-model="simulation_mode">
        <v-radio label="Abierto" :value="SimulationMode.OPEN" />
        <v-radio label="Solo compradores" :value="SimulationMode.ONLY_PURCHASERS" />
        <v-radio label="Compradores y alumnos" :value="SimulationMode.PURCHASERS_AND_PREMIUM" />
        <v-radio label="Solo alumnos" :value="SimulationMode.ONLY_PREMIUM" />
      </v-radio-group>
      <v-text-field
        v-model="maxSeconds"
        type="number"
        autocomplete="off"
        label="Duración examen en segundos"
      />
      <v-text-field
        v-model="startDate"
        type="datetime-local"
        label="Fecha de inicio"
      />
      <v-text-field
        v-model="endDate"
        type="datetime-local"
        label="Fecha de finalización"
      />
      <v-text-field
        v-model="resultsDate"
        type="datetime-local"
        label="Fecha de resultados"
      />
    </div>
    <v-row
      v-for="(q, index) in questions"
      :key="index"
      style="padding-bottom:20px;"
    >
      {{ index + 1 }}
      <v-col>
        <v-textarea
          v-model="q.text"
          auto-grow
          outlined
          rows="1"
          type="text"
          autocomplete="off"
          label="Pregunta"
        />
        <div class="options">
          <v-textarea
            v-for="opt in Object.keys(q.options).sort()"
            :key="opt"
            v-model="q.options[opt]"
            auto-grow
            outlined
            rows="1"
            type="text"
            autocomplete="off"
            :label="opt"
          />
          <v-row>
            <v-radio-group v-model="q.correcta" row>
              <v-radio v-for="opt in Object.keys(q.options).sort()" :key="opt" :label="opt" color="red" :value="opt" />
            </v-radio-group>
          </v-row>
        </div>

        <v-textarea
          v-model="q.detalle"
          auto-grow
          outlined
          rows="1"
          type="text"
          autocomplete="off"
          label="Explicación"
        />
        <v-divider />
      </v-col>

      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="primary"
        @click="questions.splice(index, 1)"
      >
        <v-icon dark>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-row>

    <v-btn
      class="mx-2"
      fab
      dark
      color="red"
      @click="insertEmptyQuestion"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-btn class="mx-2" fab dark color="blue" @click="testIdToEdit ? updateTest() : submitTest()">
      <v-icon dark>
        mdi-upload
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import Vue from "vue";
import * as firebase from "firebase/app";
import { SimulationMode } from "@/models/simulation_mode/interface";

export default Vue.extend({
  name: "TestInput",
  // components: { QuestionInput },
  props: {
    testIdToEdit: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: null,
      questions: [],
      numOptions: 4,
      created: null,
      updated: null,
      category: null,
      tags: [],

      isTimed: null,
      maxSeconds: null,
      startDate: null,
      endDate: null,
      resultsDate: null,
      simulation_mode: null,
      SimulationMode: SimulationMode,

      loading: true,
      availableCategories: null,
      userIsAdmin: false,
      isEditing: true,

      shuffleAtEnd: false,
    };
  },
  created() {
    this.getAdmin();
    if (this.testIdToEdit) {
      firebase
        .firestore()
        .collection("tests")
        .doc(this.testIdToEdit)
        .get()
        .then((doc) => {
          const test = doc.data();
          this.title = test.title;
          this.category = test.category || 'SIN CATEGORIA';
          this.isTimed = test.isTimed;
          this.maxSeconds = test.maxSeconds;
          this.simulation_mode = test.simulation_mode;
          
          this.startDate = test.startDate ? test.startDate.toDate().toISOString().slice(0,16) : null;
          this.endDate = test.endDate ? test.endDate.toDate().toISOString().slice(0,16) : null;
          this.resultsDate = test.resultsDate ? test.resultsDate.toDate().toISOString().slice(0,16) : null;
         
          this.questions = this.optionifyQuestions(test.questions);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    this.$store
      .dispatch("tests/getTestCategories")
      .then((categories) => {
        this.availableCategories = categories.map((c) => c.code);
        this.availableCategories.unshift("SIN CATEGORIA");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (
        !window.confirm(
          "¿Seguro que quieres salir? Los cambios no se guardarán."
        )
      ) {
        return;
      }
    }
    next();
  },
  methods: {
    insertEmptyQuestion() {
      this.questions.push({
          question: null,
          options: Object.fromEntries([...Array(parseInt(this.numOptions)).keys()].map(x => [String.fromCharCode('a'.charCodeAt(0) + x), null])),
          correcta: null,
          detalle: null,
        })
    },
    optionifyQuestions(questions) {
      const qs = [];
      questions.forEach(q => {
        const optionifiedQuestion = {... q}
        optionifiedQuestion.options = optionifiedQuestion.options || Object.fromEntries(Object.keys(q).filter(k => k.length == 1).map(k => [k, q[k]]))
        qs.push(optionifiedQuestion)       
      })
      return qs;
    },
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    getAdmin() {
      firebase
        .firestore()
        .collection("resources")
        .doc("admin-users")
        .get()
        .then((doc) => {
          const found = doc.data().list.includes(this.$store.state.user.id);
          this.userIsAdmin = found;
          if (!found) {
            this.$router.push({ name: "home" });
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    isValid() {
      let message = "";
      if (!this.title) message += "\n Falta el título";
      if (!this.category) message += "\n Falta la categoría";

      if (this.isTimed) {
        if (!this.startDate)
          message += "\n Falta la fecha de inicio del simulacro";
        if (!this.endDate) message += "\n Falta la fecha de fin del simulacro";
        if (!this.resultsDate)
          message +=
            "\n Falta la fecha de publicación de resultados del simulacro";
        if (!this.maxSeconds) message += "\n Falta la duración del simulacro";
        if (this.simulation_mode == null) message += "\n Falta la modalidad del simulacro";
      }

      for (let i = 0; i < this.questions.length; ++i) {
        const q = this.questions[i];
        if (!(q.text && q.correcta)) {
          message = message + "\n La pregunta " + (i + 1) + " está incompleta"; //TODO: Optionificar
        }
      }
      if (message) {
        alert(message);
        return false;
      }
      else return true;
      
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    submitTest() {
      if (this.isValid())
        if (confirm("¿Seguro que quieres subir el test?"))
          try {
            if (this.title && this.category) {
              const test = {
                  title: this.title,
                  category: this.category == 'SIN CATEGORIA' ? null : this.category,
                  questions: this.shuffleAtEnd ? this.shuffle(this.questions) : this.questions,
                  length: this.questions.length,
                  created: new Date(),
                  updated: null,
                }
              if (this.isTimed) {
                test.category =    null;
                test.category_when_public = this.category;
                test.isTimed =     this.isTimed;
                test.maxSeconds =  parseInt(this.maxSeconds);
                test.startDate =   new Date(this.startDate);
                test.endDate =     new Date(this.endDate);
                test.resultsDate = new Date(this.resultsDate);
                test.simulation_mode = this.simulation_mode;
              }
              firebase
                .firestore()
                .collection("tests")
                .add(test)
                .then((docRef) => {
                  alert(
                    "El test se ha subido correctamente. El ID del test es " +
                      docRef.id
                  );
                  this.isEditing = false;
                  this.$router.go(-1);
                });
            }
          } catch (error) {
            alert(
                    "Ha habido un problema al subir el test" + error
              );
            console.error(error);
          }
    },
    updateTest(){
      if (this.isValid()) {
        if (confirm("¿Seguro que quieres actualizar el test?")) {
          const test = {
              title: this.title,
              category: this.category == 'SIN CATEGORIA' ? null : this.category,
              questions: this.shuffleAtEnd ? this.shuffle(this.questions) : this.questions,
              length: this.questions.length,
              updated: new Date(),
              isTimed: this.isTimed || null
          }
          if (this.isTimed) {
            test.category =    null;
            test.category_when_public = this.category == 'SIN CATEGORIA' ? null : this.category;
            test.maxSeconds =  parseInt(this.maxSeconds);
            test.startDate =   new Date(this.startDate);
            test.endDate =     new Date(this.endDate);
            test.resultsDate = new Date(this.resultsDate);
            test.simulation_mode = this.simulation_mode;
          }
          firebase
            .firestore()
            .collection("tests")
            .doc(this.testIdToEdit)
            .update(test)
            .then(() => {
              alert(
                "El test se ha actualizado correctamente."
              );
              this.isEditing = false;
              this.$router.go(-1);
            });
        }
      }
    }
  },
});
</script>

<style>
.page {
  padding: 190px;
  padding-top: 40px;
  background: lightgray;
  height: 100%;
}

.options {
  padding-left: 40px;
}
</style>
