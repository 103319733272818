
































import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";

import Question from "@/components/test/Question.vue";
import Pager from "@/components/test/Pager.vue";

import { default as QuestionType } from "@/models/question/interface";
import { Answer } from "@/models/answer/interface";
import UserSubmission from "@/models/user_submission/interface";
import { LogLevels } from "@/models/log/interface";
import Test from "@/models/test/interface";
import { PermissionLevel } from "@/models/permissions/interface";
import SubscriptionNeeded from "@/components/SubscriptionNeeded.vue";
import { SimulationMode } from "@/models/simulation_mode/interface";


export interface ExamQuestion {
  index: number;
  question: QuestionType;
  answer: Answer;
}

export enum QuestionsToShow {
  ALL,
  WRONG
}

export default Vue.extend({
  name: "Test",
  components: {
    Question,
    Pager,
    SubscriptionNeeded
  },
  props: {
    testId: {
      type: String,
      required: true
    },
    submission: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      pager: {
        page: 1,
        disabled: false,
        length: 10,
        perPage: 3,
        finishEnabled: !(
          this.submission !== null && this.submission.ended !== null
        ),
        filter: QuestionsToShow.ALL
      },
      isReviewMode: this.submission !== null && this.submission.ended !== null,
      loading: true,
      pages: [],
      exam: [] as ExamQuestion[],
      isEditing: false,
      currentSubmissionId: null,
      testMetadata: null,
      subNeededDialog: false
    };
  },
  computed: {
    displayedQuestions(): Array<ExamQuestion> {
      return this.paginate(this.filteredQuestions);
    },

    filteredQuestions(): Array<ExamQuestion> {
      let filter = q => true;
      if (this.pager.filter == QuestionsToShow.WRONG) {
        filter = q => q.answer != q.question.correcta;
      }
      return this.exam.filter(filter);
    }
  },
  watch: {
    "pager.perPage"() {
      this.setPages();
      this.pager.page = 1;
    },
    "pager.filter"() {
      this.setPages();
    }
  },
  async created() {
    this.$store
      .dispatch("tests/getExam", this.testId)
      .then(test => {
        this.testMetadata = test;
        this.userCanAccessTest(this.testId).then(accessGranted => {
          if (accessGranted) {
            this.initTest(this.optionifyQuestions(test.questions));
            this.setPages();
            if (!this.isReviewMode) {
              this.isEditing = true;
              if (test.isTimed) this.initTimedSubmission(test);
              else this.initRegularSubmission();
            }
          } else {
            this.$router.go(-1);
          }
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (
        !window.confirm(
          "¿Seguro que quieres salir? Los cambios no se guardarán."
        )
      ) {
        return;
      }
    }
    next();
  },

  methods: {
    userIsPremium() {
      return this.$store.state.user.subscribedSince[PermissionLevel.PREMIUM]
    },
    optionifyQuestions(questions) {
      const qs = [];
      questions.forEach(q => {
        const optionifiedQuestion = {... q}
        optionifiedQuestion.options = optionifiedQuestion.options || Object.fromEntries(Object.keys(q).filter(k => k.length == 1).map(k => [k, q[k]]))
        qs.push(optionifiedQuestion)       
      })
      return qs;
    },
    getActiveSimulationTestId() {
      return this.$store.dispatch("tests/getActiveSimulationTestId")
    },
    async userCanAccessTest(testId) {
      if (this.isReviewMode) return true;

      if (this.testMetadata.isTimed) {
        switch (this.testMetadata.simulation_mode) {
          case SimulationMode.OPEN:
            return true;
          case SimulationMode.ONLY_PURCHASERS: {
            const access = await this.userIsPurchaser(testId);
            if (!access) {
              this.$store.commit("showMessage", {
                text: "Este simulacro es de pago",
                color: "error"
              });
            }
            return access;
          }
          case SimulationMode.PURCHASERS_AND_PREMIUM: {
            const prem = this.userIsPremium();
            if (prem) return true;
            const purc = await this.userIsPurchaser(testId);
            if (!purc) {
              this.$store.commit("showMessage", {
                text: "Este simulacro es para compradores y alumnos",
                color: "error"
              });
            }
            return purc;
          }
          case SimulationMode.ONLY_PREMIUM: {
            const prem = this.userIsPremium();
            if (!prem) {
              this.$store.commit("showMessage", {
                text: "Este simulacro es solo para alumnos",
                color: "error"
              });
            }
            return prem;
          }
        }
      } else {
        const access = this.userIsPremium();
        if (!access) {
          this.$store.commit("showMessage", {
            text: "Necesitas una suscripción a la academia para acceder al contenido",
            color: "error"
          });
        }
        return access;
      }
    },
    async userIsPurchaser(testId) {
        const userEmail = this.$store.state.user.email;
        return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("resources")
          .doc("restricted_tests")
          .get()
          .then(doc => {
            if (doc.exists) {
              if (doc.get(testId)) {
                const data = doc.data()
                if (data[testId].includes(userEmail)) {
                  resolve(true)
                } else {
                  resolve(false);
                }
              } else {
                resolve(false);
              }
            } else {
              reject("Document restricted_tests does not exist");
            }
          })
          .catch(error => {
            console.log(error);
            resolve(false);
          });
      });
    },
    initRegularSubmission() {
      const emptySubmission: UserSubmission = {
        submissionId: null,
        userId: this.$store.state.user.id,
        started: new Date(),
        ended: null,
        answers: null,
        testId: this.testId,
        position: null,
        grade: null,
        testName: this.testMetadata.title
      };
      this.$store
        .dispatch("tests/startSubmission", emptySubmission)
        .then((submission: UserSubmission) => {
          this.currentSubmissionId = submission.submissionId;
        })
        .catch(error => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
        });
    },

    initTimedSubmission(test) {
      // First check if user has existing submission for the test
      // If there is a submission it is taken and time is continued
      // If there isn't any, a fresh submission is created
      const currentDate = new Date();
      const isExamPeriod =
        test.startDate.toDate() <= currentDate &&
        currentDate <= test.endDate.toDate();
      if (test.startDate.toDate() > currentDate) {
        this.exitPage("El examen todavía no ha empezado");
        return;
      }
      else if (!isExamPeriod) {
        this.exitPage("El examen no está disponible");
        return;
      } else {
        this.$store
          .dispatch("tests/getUserSubmission", {
            testId: this.testId,
            userId: this.$store.state.user.id
          })
          .then(existingSubmission => {
            if (existingSubmission != null) {
              if (existingSubmission.ended) {
                this.exitPage("Tu examen ya ha sido registrado.");
              } else if (
                !existingSubmission.ended &&
                this.getUnixSeconds() - existingSubmission.started.seconds >=
                  test.maxSeconds
              ) {
                this.exitPage("Tu examen no se ha entregado dentro del plazo");
              } else {
                this.currentSubmissionId = existingSubmission.submissionId;
                this.initializeClock(
                  "clockdiv_exam",
                  existingSubmission.started.seconds + test.maxSeconds
                );
              }
            } else {
              const emptySubmission: UserSubmission = {
                submissionId: null,
                userId: this.$store.state.user.id,
                started: new Date(),
                ended: null,
                answers: null,
                testId: this.testId,
                position: null,
                grade: null,
                testName: this.testMetadata.title
              };
              if (confirm('¿Estás listo para empezar el examen? Una vez empiece a contar el tiempo tendrás que entregarlo en el plazo establecido.')) {
                this.$store
                  .dispatch("tests/startSubmission", emptySubmission)
                  .then(submission => {
                    this.currentSubmissionId = submission.submissionId;
                    this.initializeClock(
                      "clockdiv_exam",
                      submission.started.seconds + test.maxSeconds
                    );
                  })
                  .catch(error => {
                    this.$store.dispatch("log", {
                      level: LogLevels.ERROR,
                      message: error
                    });
                  });
              } else {
                this.isEditing = false;
                this.$router.go(-1);
              }
            }
          });
      }
    },

    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },

    getTimeRemaining: function(endtimeSecs: number) {
      const total = (endtimeSecs - this.getUnixSeconds()) * 1000;
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return {
        total,
        days,
        hours,
        minutes,
        seconds
      };
    },

    initializeClock: function(id: string, endtime: number) {
      const clock = document.getElementById(id);
      this.examinterval = setInterval(() => {
        const t = this.getTimeRemaining(endtime);
        clock.innerHTML =
          "⏳ Te queda " +
          (t.hours < 10 ? "0" : "") +
          t.hours +
          ":" +
          (t.minutes < 10 ? "0" : "") +
          t.minutes +
          ":" +
          (t.seconds < 10 ? "0" : "") +
          t.seconds +
          " ⏳";
        if (t.total <= 0) {
          clearInterval(this.examinterval);
          this.submitExam();
        }
      }, 1000);
    },

    getUnixSeconds(): number {
      return Math.floor(Date.now() / 1000);
    },

    initTest(questions: Array<QuestionType>) {
      for (let index = 0; index < questions.length; index++) {
        const examQuestion: ExamQuestion = {
          index: index,
          question: questions[index],
          answer: this.isReviewMode ? this.submission.answers[index] : null
        };
        this.exam.push(examQuestion);
      }
    },

    setPages() {
      this.pager.length = Math.ceil(
        this.filteredQuestions.length / this.pager.perPage
      );
      for (let index = 1; index <= this.pager.length; index++) {
        this.pages.push(index);
      }
    },

    paginate(exam: Array<ExamQuestion>): Array<ExamQuestion> {
      const page = this.pager.page;
      const perPage = this.pager.perPage;
      const from = page * perPage - perPage;
      const to = page * perPage;
      return exam.slice(from, to);
    },

    nextPage() {
      this.pager.page = this.pager.page + 1;
    },

    registerAnswer(id: number, selected: Answer) {
      this.exam[id].answer = selected;
    },

    checkUnansweredQuestionsAndNotify(): boolean {
      const c = [];
      for (let i = 0; i < this.exam.length; ++i) {
        if (this.exam[i].answer == null) {
          c.push(i);
        }
      }
      if (c.length > 0) {
        // TODO: Use a dialog
        return confirm(
          this.$t("views/test/unanswered_questions", {
            questions: "\n" + c + "\n\n"
          }) as string
        );
      } else {
        return true;
      }
    },

    gradeTest() {
      const sumPerCorrect = 10.0 / this.exam.length;
      const substractPerWrong = sumPerCorrect / 4.0;
      let grade = 0.0;
      this.exam.forEach(question => {
        if (question.question.correcta == question.answer) {
          grade += sumPerCorrect;
        } else {
          // Unselected questions do not substract
          if (question.answer) {
            grade -= substractPerWrong;
          }
        }
      });
      if (grade < 0) grade = 0.0;
      return grade.toLocaleString(undefined, {
        minimumFractionDigits: 2
      });
    },

    finishExam() {
      //this.checkUnanswered();
      if (
        confirm(
          "Estás a punto de finalizar el examen. No podrás editar tus respuestas ¿Quieres continuar?"
        )
      ) {
        this.submitExam();
      }
    },

    submitExam() {
        const grade = this.gradeTest();
        this.$store
          .dispatch("tests/finishSubmission", {
            submissionId: this.currentSubmissionId,
            answers: this.exam.map(q => q.answer),
            ended: new Date(),
            testId: this.testId,
            userId: this.$store.state.user.id,
            grade: this.testMetadata.isTimed ? 'Próximamente' : grade
          })
          .then(() => {
            this.isEditing = false;
            if (this.testMetadata.isTimed) {
              this.exitPage("Tu examen se ha registrado correctamente");
            } else {
              this.goToCorrection();
            }
          })
          .catch(error => {
            alert("Ha habido un error registrando tu respuesta");
            this.$store.dispatch("log", {
              level: LogLevels.ERROR,
              message: error
            });
          });
    },

    goToCorrection() {
      this.$router.push({
        name: "result",
        params: { testId: this.testId, submissionId: this.currentSubmissionId }
      });
    },

    exitPage(message: string) {
      clearInterval(this.examinterval);
      this.isEditing = false;
      alert(message);
      this.$router.push({ name: "landing_simulacro" });
    }
  }
});
