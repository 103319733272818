const NONE = 0; // No user loged in
const REGISTERED = 1; // User loged in
const VALIDATED = 2; // User loged in and email validated
const TESTS = 3; // User loged in and email validated and access to tests
const PREMIUM = 4; // User loged in with email validated and subscription active
const MADRID = 5; // User loged in with email validated and subscription active to Comunidad de Madrid

export const PermissionLevel = {
  NONE: NONE,
  REGISTERED: REGISTERED,
  VALIDATED: VALIDATED,
  TESTS: TESTS,
  PREMIUM: PREMIUM,
  MADRID: MADRID
};

export type PermissionLevel = number;
