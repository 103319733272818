import axios from "axios";
// import firebase from 'firebase';
import { LogLevel, LogLevels } from "@/models/log/interface";
import * as Sentry from "@sentry/browser";

interface ActionParam {
  state: any;
  commit: (mutation: string, value?: any) => void;
  dispatch: (action: string, value?: any) => any;
  rootState: any;
}

export const consumeMessage = (args: ActionParam) => {
  const message = args.state.messages[0];
  args.commit("consumeMessage");
  return message;
};

export const getRemoteDate = (): Promise<Date> => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now")
      .then(response => {
        resolve(new Date(response.data * 1000));
      })
      .catch(error => {
        reject(error);
      });
  });
};

function constructMessage(params: {
  level: LogLevel;
  message: string;
  payload?: any;
}) {
  let message = `[${params.level}] ${params.message}`;
  if (params.payload) {
    message += ` ${params.payload}`;
  }
  return message;
}

function getConsoleLogger(level: LogLevel) {
  if (level == LogLevels.ERROR) {
    return console.error;
  } else if (level == LogLevels.WARNING) {
    return console.warn;
  } else if (level == LogLevels.INFO) {
    return console.info;
  } else {
    return console.log;
  }
}

export const log = (
  args: ActionParam,
  params: { level: LogLevel; message: string; payload?: any }
): void => {
  if (process.env.NODE_ENV != "production") {
    getConsoleLogger(params.level)(constructMessage(params));
  } else {
    if (params.level == LogLevels.ERROR) {
      Sentry.captureException(new Error(params.payload));
    } else if (params.level == LogLevels.WARNING) {
      /**/
    } else if (params.level == LogLevels.INFO) {
      /**/
    } else {
      /**/
    }
  }
  // firebase.analytics().logEvent(params.level, {
  //   'payload': params.payload,
  //   'state': JSON.stringify(args.rootState)
  // });
};
