<template>
  <div v-if="!loading" style="background: #171E36;">
    <div class="pa-10 section-title" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size: 38px;'">
      Temas recientes
    </div>
    <v-slide-group
      class="pa-1 my-slider"
      show-arrows
      dark
    >
      <v-slide-item
        v-for="t in mega_tu"
        :key="t.id"
      >
        <teaching-unit-card 
          :t="t" 
          @subscription-required="$emit('subscription-required')" 
        />
      </v-slide-item>
    </v-slide-group>
    <div style="padding-bottom: 50px; text-align: center; color: white;">
      <v-dialog v-model="exploreContentDialog" :width="$vuetify.breakpoint.smAndDown ? '90%' : '50%'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#C2292F"
            dark
            large
            v-bind="attrs"
            style="border-radius: 0px;"
            v-on="on"
          >
            ver más
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline pa-6">
            Contenido
          </v-card-title>
          <v-divider />
          <v-expansion-panels accordion hover>
            <v-expansion-panel v-for="[cat, items] of Object.entries(groupByCategory(og_mega_tu))" :key="cat">
              <v-expansion-panel-header> 
                {{ cat }}
              </v-expansion-panel-header>
              <v-expansion-panel-content v-for="i in items" :key="i.id">
                <v-expansion-panels accordion flat hover>
                  <v-expansion-panel style="border: black;">
                    <v-expansion-panel-header>
                      {{ i.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-for="lesson in i.lessons" :key="lesson.url">
                      <v-icon left black>
                        mdi-play
                      </v-icon>
                      {{ lesson.name }}
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-for="doc in i.documents" :key="doc.url">
                      <v-icon left black>
                        mdi-file
                      </v-icon>
                      {{ doc.name }}
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-for="test in i.tests" :key="test.url">
                      <v-icon left black>
                        mdi-check
                      </v-icon>
                      {{ test.name }}
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-for="lin in i.links" :key="lin.url">
                      <v-icon left black>
                        mdi-link
                      </v-icon>
                      {{ lin.name }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="accent"
              text
              @click="exploreContentDialog = false"
            >
              Entendido
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
  <div v-else style="background: #171E36; height: 400px;" />
</template>

<script>
import Vue from "vue";
import * as firebase from "firebase/app";
import TeachingUnitCard from "@/components/TeachingUnitCard.vue";
export default Vue.extend({
  name: "OurContent",
  components: {
    TeachingUnitCard,
  },
  data() {
    return {
      mega_tu: null,
      og_mega_tu: null,
      loading: true,
      shownTus: 20,
      exploreContentDialog: false
    }
  },
  created() {
    this.getMegaTu().then(megaTu => {
      this.mega_tu = megaTu;
      this.og_mega_tu = JSON.parse(JSON.stringify(megaTu));
      this.mega_tu = this.prepareMegaTu(megaTu);
      this.loading = false;
    })
  },
  methods: {
    groupByCategory(mega_tu) {
      const groups = mega_tu.reduce((groups, item) => {
        const group = (groups[item.display_category.name] || []);
        group.push(item);
        groups[item.display_category.name] = group;
        return groups;
      }, {});
      delete groups.undefined;
      return groups;
    },
    prepareMegaTu(mega_tu) {
      return mega_tu.slice(0,this.shownTus);
    },
    getMegaTu() {
          return new Promise((resolve, reject) => {
            firebase
              .firestore()
              .collection("aggregated")
              .doc("mega_tu")
              .get()
              .then(doc => {
                if (doc.exists) {
                  resolve(doc.data().list);
                } else {
                  reject("Document does not exist");
                }
              })
              .catch(error => {
                reject(error);
              });
          });
        },
    
  }

});
</script>

<style>

.section-title {
  font-family: 'Oswald', sans-serif;
  text-align: center; 
  color: white; 
  font-weight: 350;
}

</style>