<template>
  <div class="container-ponentes mb-4">
    <div class="ponentes">
      <div
        class="pa-10 section-title"
        :style="
          $vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size: 38px;'
        "
      >
        Conoce el equipo
      </div>
      <div class="ponentes-container d-flex flex-row flex-wrap justify-center">
        <div v-for="(item, index) in paginatedPonentes" :key="index">
          <div class="ponente-item">
            <img class="avatar" :src="item.foto" height="100px" width="100px" />
            <br />
            <strong>{{ item.nombre }}</strong>
            <small>{{ item.descripcion }}</small>
          </div>
        </div>
      </div>
      <div>
        <v-btn
          v-if="currentPage * maxPerPage < ponentes.length"
          color="#C2292F"
          class="mt-3"
          dark
          large
          @click="loadMore"
        >
          Ver más
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import RamonBarrios from "@/assets/ponentes/ramon.jpeg";
import JairPereira from "@/assets/ponentes/jair.jpeg";
import FelipeRamos from "@/assets/ponentes/felipe.jpeg";
//import AlfonsoBermejo from "@/assets/ponentes/alfonso.jpeg";
import AlexCordoba from "@/assets/ponentes/acorma.jpeg";
import AlexFornes from "@/assets/ponentes/fornes.jpeg";
import JoseAntonioMarin from "@/assets/ponentes/jose_antonio.jpeg";
import Patricia from "@/assets/ponentes/patricia.jpeg";
// import Alvaro from "@/assets/ponentes/alvaro.jpeg";
import BrendaRodriguez from "@/assets/ponentes/brenda.jpeg";
import LauraGarcia from "@/assets/ponentes/laura.jpeg";
import PabloLomeli from "@/assets/ponentes/pablo.jpeg";
// import SergiMassegur from "@/assets/ponentes/sergi.jpeg";
import ArturoHomedes from "@/assets/ponentes/arturo_homedes.jpeg";
import LeoNovarese from "@/assets/ponentes/leo_novarese.jpeg";
import PedroCandel from "@/assets/ponentes/pedro_candel.jpeg";
import AlineEick from "@/assets/ponentes/aline.jpeg";
import Dedy from "@/assets/ponentes/dedy.jpeg";
import JavierCarpes from "@/assets/ponentes/javiercarpes.jpeg";
import Roberto from "@/assets/ponentes/roberto.jpeg";
import Marcial from "@/assets/ponentes/marcial.jpeg";
import Carmelo from "@/assets/ponentes/carmelo.jpeg";
import Natalia from "@/assets/ponentes/natalia.jpeg";
import Ale_canas from "@/assets/ponentes/ale_canas.jpeg";

export default {
  name: "PonentesLanding",
  data() {
    return {
      ponentes: [
        {
          nombre: "Ramón Barrios",
          descripcion: "Director de Oposiciones Bombero",
          foto: RamonBarrios,
          link: "https://instagram.com/oposicionesbombero",
        },
        {
          nombre: "Jair Pereira",
          descripcion: "Cabo Consorcio Provincial de Bomberos de Málaga",
          foto: JairPereira,
          link: "https://instagram.com/_jair_p_r",
        },
        {
          nombre: "Carmelo Vega",
          descripcion: "Jefe supervisor bomberos Madrid",
          foto: Carmelo,
          link: "",
        },
        {
          nombre: "Natalia Lorenzo",
          descripcion: "Psicóloga de emergencias",
          foto: Natalia,
          link: "",
        },
        {
          nombre: "Alejandro Cañas",
          descripcion: "Coordinador preparación física",
          foto: Ale_canas,
          link: "",
        },
        {
          nombre: "Alex Cordoba",
          descripcion: "Bombero en Consorcio de Málaga",
          foto: AlexCordoba,
          link: "",
        },
        {
          nombre: "Alex Fornes",
          descripcion: "Bombero en Ayuntamiento de Valencia",
          foto: AlexFornes,
          link: "",
        },
        {
          nombre: "Jose Antonio Marín Ayala",
          descripcion: "Sargento Consorcio de Murcia",
          foto: JoseAntonioMarin,
          link: "",
        },
        {
          nombre: "Patricia",
          descripcion: "Entrenadora",
          foto: Patricia,
          link: "",
        },
        {
          nombre: "Brenda Rodríguez",
          descripcion: "Psicóloga",
          foto: BrendaRodriguez,
          link: "",
        },
        {
          nombre: "Laura García",
          descripcion: "Nutricionista",
          foto: LauraGarcia,
          link: "",
        },
        {
          nombre: "Pablo Lomeli",
          descripcion: "Técnicas de estudio",
          foto: PabloLomeli,
          link: "",
        },
        {
          nombre: "Arturo Homedes",
          descripcion: "Bomber de la Generalitat de Catalunya",
          foto: ArturoHomedes,
          link: "",
        },
        {
          nombre: "Leo Novarese",
          descripcion: "Bomber Barcelona",
          foto: LeoNovarese,
          link: "",
        },
        {
          nombre: "Pedro Candel",
          descripcion:
            "Bombero del S.E.I.S del Ayuntamiento de Talavera de la Reina (Toledo)",
          foto: PedroCandel,
          link: "",
        },
        {
          nombre: "Aline Eick",
          descripcion: "Bombera de la Generalitat de Catalunya",
          foto: AlineEick,
          link: "",
        },
        {
          nombre: "Dedy",
          descripcion: "Bombero de Melilla",
          foto: Dedy,
          link: "",
        },
        {
          nombre: "Javier Carpes",
          descripcion: "Licenciado en derecho. Abogado",
          foto: JavierCarpes,
          link: "",
        },
        {
          nombre: "Roberto Curbelo",
          descripcion: "Cabo de Bomberos en Gran Canaria",
          foto: Roberto,
          link: "",
        },
        {
          nombre: "Marcial González",
          descripcion: "Sargento en Huelva",
          foto: Marcial,
          link: "",
        },
      ],
      currentPage: 2,
      maxPerPage: 4,
      showReadMore: true,
    };
  },
  computed: {
    totalResults() {
      return Object.keys(this.ponentes).length;
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    resultShowMore() {
      return this.maxPerPage * this.currentPage;
    },
    paginatedPonentes() {
      return this.ponentes.slice(0, this.currentPage * this.maxPerPage);
    },
  },
  methods: {
    loadMore() {
      this.currentPage += 1;
    },
  },
};
</script>

<style scoped>
.container-ponentes {
  display: flex;
  justify-content: flex-end;
}

.ponentes {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.ponente-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 250px;
  width: 250px;
  padding: 20px;
  color: whitesmoke;
  background: #0a0d1a;
  margin: 0px 20px 20px 20px;
  border-bottom: 6px solid;
  border-bottom-color: crimson;
  border-radius: 0.5rem;
}
.avatar {
  border-radius: 50%;
}
#content-wrap {
  padding-bottom: 8rem; /* Footer height */
}

@media screen and (max-width: 615px) {
  .titulo-ponentes {
    font-size: 20px;
  }

  .ponente-item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100px;
    padding: 10px;
    border-bottom: 3px solid;
    border-bottom-color: crimson;
  }

  .ponente-item strong {
    font-size: 0.8rem;
    max-width: 30%;
  }

  .ponente-item small {
    font-size: 0.7rem;
    max-width: 40%;
  }

  .avatar {
    width: 3rem;
    height: 3rem;
    max-width: 20%;
  }
}
</style>
